import { Component, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor {

  private destroy = new Subject<void>();

  constructor(private i18n: I18nService) {}

  @Input() fileLimit: number = 1;
  @Input() maxFileSize: number = 1;
  @Input() acceptedFileTypes: string[] = ['pdf', 'docx', 'txt'];
  @Output() filesUploaded = new EventEmitter<File[]>();

  files: File[] = [];
  dragOver: boolean = false;
  errorMessage: string = '';
  lblDragHere: string;
  lblUploadDocOne: string;
  lblUploadDocMultiple: string;
  lblSupportedFiles: string;
  acceptedFileTypesString : string;

  private onChange!: (files: File[]) => void;
  private onTouched!: () => void;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  onFilesAdded(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.validateFiles(Array.from(input.files));
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.dragOver = true;
  }

  onDragLeave() {
    this.dragOver = false;
    this.errorMessage = '';
  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblDragHere = "Glissez ici ou cliquez pour télécharger";
            this.lblUploadDocOne = `Vous pouvez télécharger un document d'une taille maximale de ${this.maxFileSize} Mo.`;
            this.lblUploadDocMultiple = `Vous pouvez télécharger jusqu'à ${this.fileLimit} documents ou un total de ${this.maxFileSize} Mo.`;
            this.lblSupportedFiles = `Format de fichier pris en charge: ${this.acceptedFileTypes.join(', ').toUpperCase()}`;
            break;
        
        default:
            this.lblDragHere = "Drag Here or click to upload";
            this.lblUploadDocOne = `You can upload a document up to ${this.maxFileSize} MB in size.`;
            this.lblUploadDocMultiple = `You can upload up to ${this.fileLimit} documents up to ${this.maxFileSize} MB in size.`;
            this.lblSupportedFiles = `Supported file format: ${this.acceptedFileTypes.join(', ').toUpperCase()}`;
            break;
        }
      });
  }

  ngOnInit(): void {
    this.manageLocale();
    this.updateAcceptedFileTypesString();
  }

  ngOnChanges(): void {
    this.updateAcceptedFileTypesString();
  }

  updateAcceptedFileTypesString(): void {
    this.acceptedFileTypesString = this.acceptedFileTypes.map(ext => `.${ext.trim()}`).join(',');
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.dragOver = false;
    if (event.dataTransfer?.files) {
      this.validateFiles(Array.from(event.dataTransfer.files));
    }
  }

  validateFiles(files: File[]) {
    const validFiles: File[] = [];
    const currentFileCount = this.files.length;

    for (const file of files) {
      if (this.validateFileSize(file) && this.validateFileType(file)) {
        validFiles.push(file);
      }
    }

    if (currentFileCount + validFiles.length > this.fileLimit) {
      this.errorMessage = `You can upload a maximum of ${this.fileLimit} files.`;
      return;
    }

    this.files = [...this.files, ...validFiles];
    if (this.files.length > 0 && validFiles.length > 0) {
      this.errorMessage = '';
    }
    this.filesUploaded.emit(this.files);
    this.onChange(this.files);
  }

  validateFileSize(file: File): boolean {
    const sizeInMB = file.size / 1024 / 1024;
    if (sizeInMB > this.maxFileSize) {
      this.errorMessage = `File size of ${file.name} exceeds the limit of ${this.maxFileSize}MB.`;
      return false;
    }
    return true;
  }

  validateFileType(file: File): boolean {
    const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? '';
    return this.acceptedFileTypes.includes(fileExtension);
  }

  writeValue(files: File[]): void {
    this.files = files || [];
    if (!this.files.length) {
      this.errorMessage = '';
    }
  }

  registerOnChange(fn: (files: File[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  clearFiles() {
    this.files = [];
    this.onChange(this.files);
    this.filesUploaded.emit(this.files);
    this.errorMessage = '';
  }
}
