import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Agent } from '../model/agent.model';
import { Router } from '@angular/router';
import { RecentAgentsService } from '../Services/recent-agent.service';
import { AgentsService } from '../Services/agent.service';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from '../Services/LoadingService';
import { error } from 'jquery';
import { ErrorComponent } from '../error/error.component';
import { AppComponentService } from '../Services/appcomponentservice';
import { ChatService } from '../Services/chat.service';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { Themes } from '@usitsdasdesign/dds-ng/shared';

@Component({
  selector: 'app-recent-agents',
  templateUrl: './recent-agents.component.html',
  styleUrls: ['./recent-agents.component.css']
})
export class RecentAgentsComponent implements OnInit {
  recentPaths$ = this.recentAgentsService.recentPaths$;
  private agents: Agent[] = [];
  private destroy = new Subject<void>(); 
  language: string = 'en';
  lblRecentlyUsedAgents: string;
  @Output() agentClicked = new EventEmitter<void>();
  ComingSoontext: string = "Coming Soon!"

  constructor(
    private recentAgentsService: RecentAgentsService,
    private agentsService: AgentsService,
    private router: Router,
    private i18n: I18nService,  
    public loadingService: LoadingService,
    public appcomponentservice: AppComponentService,
    private appComponentService: AppComponentService,
    private chatservice:ChatService

  ) {}


  ngOnInit() {
    this.agentsService.getAgents().subscribe(agents => {
      this.agents = agents;
    });
    this.manageLocale();

    this.chatservice.verifyEFSUser().subscribe((response: any) => {
      if (response.result == "Failure") {
        this.appComponentService.setIsEFSSUser(false);
        // this.agentService.updateAgentDisabledStatus()
        
      }
      else 
        this.appComponentService.setIsEFSSUser(true);
      
    })

  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.language = 'fr'
            this.lblRecentlyUsedAgents = "Agents utilisés récemment";
            break;
          default:
            this.language = 'en'
            this.lblRecentlyUsedAgents = "Recently Used Agents";
            break;
        }
      });
  }   

  navigateToPath(path: string) {
    if(path === "/deloitte-voice"){
      return;
   }
    // debugger;
    if (this.appComponentService.isEFSUser == false && path === '/efs') {
      return; // Exit the method without performing any action
    }
    else if (this.appComponentService.isEFSUser == false && path == "/efs" ){
      // this.agentsService.updateAgentDisabledStatus()
      this.router.navigate(['/error'])
      
    }
    else{
    if(path != "/deloitte-voice"){
       this.agentClicked.emit();
    }

    if (this.loadingService.stopstreaming) {
      return; // Exit the method if streaming is stopped
    }
    const agent = this.getAgent(path);
    if (agent && !agent.disabled && !this.isPathActive(agent.path)) {
      this.router.navigate([path]);
    }}
  }

  isPathActive(path: string): boolean {
    var exactMatch =  this.router.url === path;
    return path ? this.router.url.startsWith(`${path}/`) || exactMatch : exactMatch;
  }
 

  getAgent(path: string): Agent | undefined {
    return this.agents.find(agent => agent.path === path);
  }

  options: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'right-top',
    tooltipIndent: 15,
    tooltipIsOutsideClick: false,
    tooltipHasBeak: true,
    tooltipIsDisabled: false,
    tooltipIsInverse: false,
    tooltipIsDynamic: false,
    tooltipCustomClass: 'tooltipclass',
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipShowDelay: 0,
    tooltipHideDelay: 0,
    tooltipTheme: Themes.dark
  };
}
