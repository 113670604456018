<div class="container">
    <div class="greeting">
       <div class="header">
          <img src="../../../assets/images/rocket.png" alt="Rocket" class="rocket-image">
          <span class="header-text">{{lblNameGreeting}}, {{displayName}}!</span>
       </div>
       <div class="description">{{lblDescription}}</div>
    </div>
    <div class="cards">
      <div 
      [ddsTooltip]="ComingSoontext"
      [tooltipInvokeType]="'hover'"
      [tooltipSize]="'lg'"
      [tooltipType]="'regular'"
      [tooltipIsDisabled]="option.type !== 'create'"
        *ngFor="let option of flowOptions" [ngClass]="{'disabled': option.disabled}"
           class="card"
           (click)="selectFlow(option)">
        <h3>{{ option.title[this.language] }}</h3>
        <p>{{ option.description[this.language] }}</p>
      </div>
    </div>
</div>
