import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { protectedResources } from '../../auth-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeloitteVoiceService {
  constructor(private httpClient: HttpClient) { }
  baseUrl = protectedResources.apiDBot.endpoint;

uploadDocument(formData: FormData): any {
  const url = `${this.baseUrl}api/DeloitteVoice/UploadDocument`;
  const headers = new HttpHeaders({
    'access-control-allow-origin': '*',
    'content-type': 'multipart/form-data'
  });

  return this.httpClient.post(  
    url,    
    formData   
   );
  }

  downloadFile(fileName: string): Observable<Blob> {
    const url = `${this.baseUrl}api/DeloitteVoice/DownloadDocument?fileName=${fileName}`;
    return this.httpClient.get(url, { responseType: 'blob' });
}

reviseDocument(request: DeloitteVoiceReviseRequest): Observable<any> {
  const url = `${this.baseUrl}api/DeloitteVoice/revise`;
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.httpClient.post<any>(url, request, { headers });
}

getStatus(taskId: string): Observable<any> {
  const url = `${this.baseUrl}api/DeloitteVoice/status/${taskId}`;
  return this.httpClient.get(url);
}


}

export interface DeloitteVoiceReviseRequest {
  contentFormat: string;
  contentInput: string;
  additionalInstructions: string;
  fileName: string;
}

export interface DeloitteVoiceReviseResponse {
  taskId: string;
}

export interface DeloitteVoiceFileTrackerResponseDto {
  taskId: string; 
  status: string;
  outputFileName?: string; 
  errorMessage?: string; 
  lastUpdatedDateTime: Date;
  userId: string;
}
