import { Component, Input, Output, EventEmitter } from '@angular/core';
 
@Component({
  selector: 'app-file-download-card',
  templateUrl: './file-download-card.component.html',
  styleUrls: ['./file-download-card.component.css']
})
export class FileDownloadCardComponent {
  @Input() isDisabled: boolean = false;
  @Input() fileName: string = '';
  @Output() fileClicked = new EventEmitter<string>();
 
  onFileClick(event: MouseEvent) {
    // Prevent click from bubbling up to parent elements
    event.stopPropagation();
    if (!this.isDisabled) {
      this.fileClicked.emit(this.fileName);
    }
  }
}