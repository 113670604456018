<div class="sidebar-section">
  <div class="sidebar-header">{{lblRecentlyUsedAgents}}</div>
  <div class="sidebar-items">
    <div class="sidebar-item"
         *ngFor="let path of recentPaths$ | async"
         (click)="!loadingService.stopstreaming && navigateToPath(path)"
         [ngClass]="{'disabled-link': loadingService.stopstreaming,
         'disabled-link-efs': appComponentService.isEFSUser === false && path === '/efs'}"
         [class.active]="isPathActive(path)" >
      <ng-container *ngIf="getAgent(path) as agent" >
        <img class="sidebar-item-icon" src="../assets/images/RecentlyUsed.png" [alt]="agent.name" />
        <span class="sidebar-item-label"  [ddsTooltip]="ComingSoontext"  [tooltipPosition]="'right'"
        [tooltipTheme]= "'dark'"
        [tooltipInvokeType]="'hover'"
        [tooltipSize]="'md'"
        [tooltipType]="'regular'"
        [tooltipIsDisabled]="path != '/deloitte-voice'"
        >{{agent.name[this.language]}}</span>
      </ng-container>
    </div>
  </div>
</div>
