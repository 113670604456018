import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DeloitteVoiceService } from 'src/app/Services/deloitte-voice.service';

@Component({
  standalone: false,
  selector: 'app-deloitte-voice-completed',
  templateUrl: './deloitte-voice-completed.component.html',
  styleUrls: ['./deloitte-voice-completed.component.css']
})
export class DeloitteVoiceCompletedComponent {
  @Input() outputFileName!: string;
  @Input() lblFileReadyToDownload!: string;

  constructor(
    private documentUploadService: DeloitteVoiceService,
  ) {}
  onFileClick(fileName: string) {
    this.documentUploadService.downloadFile(fileName).subscribe((response: Blob) => {
      const blob = new Blob([response], { type: response.type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Download error:', error);
    });
  }
}
