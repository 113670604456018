import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-deloitte-voice-processing',
  templateUrl: './deloitte-voice-processing.component.html',
  styleUrls: ['./deloitte-voice-processing.component.css'],
  standalone: false
})
export class DeloitteVoiceProcessingComponent {
  @Input() contentFormat!: string;
  @Input() inputFileName!: string;
  @Input() lblContentBeingRevised!: string;
}