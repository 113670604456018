import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject, concatMap, delay, of } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { ChatService } from '../Services/chat.service';
import { v4 as uuidv4 } from 'uuid';
import { LoadingService } from '../Services/LoadingService';
import { AppComponentService } from '../Services/appcomponentservice';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { FeedbackDisLikeComponent } from '../feedback-dis-like/feedback-dis-like.component';
import { FeedbackLikeComponent } from '../feedback-like/feedback-like.component';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { HttpClient } from '@angular/common/http';
import { ResetChatService } from '../Services/reset-chat.service';
import { Subscription } from 'rxjs';
import { MarkdownService } from 'ngx-markdown';
import {ConHistoryService} from '../Services/con-history.service';
import { AppComponent } from '../app.component';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  private teamsChannelUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';

  private destroy = new Subject<void>();
  tempCopiedText: string;
  @ViewChild('tempMarkDown') tempMarkDownElement!: ElementRef;
  userName: any;
  feedbacklike: any;
  userQuery: string;
  isCopy: boolean;
  userResponse: string;
  isStreaming: boolean;
  displayName: any;
  privacy_URL: string;
  errorMesg: boolean;
  lblSorrySomething1: string;
  lblSorrySomething2: string;
  private defaultHeight: number = 0;
  private resetSubscription: Subscription;
  copyIndex: any;
  convId: any;
  private routerSubscription: Subscription;
  private conversationSubscription: Subscription;
  newchat: boolean = false;
  stopStreaming: any = false;
  conversationmessages: boolean = false;

  constructor(private modalService: ModalService, private chatService: ChatService, public loadingService: LoadingService,
    public appcomponentservice: AppComponentService, private conHistoryService: ConHistoryService,private render: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef,
    private https: HttpClient, private i18n: I18nService, private router: Router, private resetChatService: ResetChatService, private markdownService: MarkdownService) {

  }
  genDVedio: string = "https://share.synthesia.io/c6e5e768-bbcc-4910-8e9d-86b17d78b303";
  lblNameGreeting: string;
  lblHelpGreeting: string;
  lblGenDIntro1: string;
  lblGenDIntro2: string;
  lblPrivacyPolicy: string;
  lblSamplePromptLine: string;
  lblSamplePrompt1: string;
  lblSamplePrompt2: string;
  lblSamplePrompt3: string;
  lblAskQuestion: string;
  lblUserCommunity: string;
  selectedLanguage: string = 'EN';
  isLoading = false;
  disableSendButton = false;
  isDefaultText: boolean;
  userPrompt: string;
  systemModel = "GPT 3.5"
  systemMessage: string;
  responseStyle: string;
  guid = uuidv4()
  userId: string;
  regenerateFlag = false;
  userQueryLength;
  likedIndex: number = -1;
  dislikedIndex: number = -1;
  isLiked: boolean = false;
  isDisLiked: boolean = false;
  likedIndices: Set<number> = new Set();
  dislikedIndices: Set<number> = new Set();
  disabledLikeIndices = new Set<number>();
  disabledDisLikeIndices = new Set<number>();
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  textareaRef: any;
  messages = new BehaviorSubject<{ user: string; text: string }[]>([
  ]);
  messages$ = this.messages.asObservable();
  @ViewChild('dynamicTextarea') dynamicTextarea!: ElementRef;
  autoScrollEnabled: boolean = true;
  lastScrollTop: number = 0;
  showScrollToBottom: boolean = false;
  tempMessages = [];
  @ViewChild(AppComponent) appComponent!: AppComponent;

  openFeedbackDislikeModel(index, botRes) {
    if (this.appcomponentservice.isModelOpen) {
      this.modalService.close();
      this.appcomponentservice.setIsModelOpen(false);
    }
    this.appcomponentservice.setIsModelOpen(true);
    this.isDisLiked = true;
    this.dislikedIndex = index;
    this.likedIndex = -1;
    this.dislikedIndices.add(index);
    this.likedIndices.delete(index);
    this.disabledDisLikeIndices.add(index);
    var FeedbackDisLike = {
      convid: this.guid,
      userid: this.userId,
      name: this.userName,
      userQuery: this.userQuery,
      apiResponse: botRes,
      gptModel: this.appcomponentservice.systemModel,
      isDocAnalyzerActive: false,
      isEFSActive: false,
      document_Name: [],
    };
    this.modalService.open(FeedbackDisLikeComponent, { lstoptionDislike: FeedbackDisLike }).onClosed().subscribe(res => {
      this.appcomponentservice.setIsModelOpen(false);
      // const dislikeButton = document.querySelector('.dislikeButton') as HTMLElement;
      //   if (dislikeButton) {
      //       // dislikeButton.classList.remove('green-dislike');
      //       // void dislikeButton.offsetWidth;
      //       // dislikeButton.classList.add('green-dislike');
      //       dislikeButton.style.backgroundColor='green';
      //   }
      //   else {
      // }

      // const likeButton = document.querySelector('.likeButton');
      // if (likeButton) {
      //     likeButton.remove();
      // }

    });
  }

  doLiked(index: number): boolean {
    return this.likedIndices.has(index);
  }
  doDisliked(index: number): boolean {
    return this.dislikedIndices.has(index);
  }

  onScroll(event: Event): void {
    const container = this.chatContainer.nativeElement;
    const currentScrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    // Check if user is scrolling down or up
    if (currentScrollTop < this.lastScrollTop) {
      this.autoScrollEnabled = false;
    }

    // Determine if the user is at the bottom
    const atBottom = scrollHeight - clientHeight <= currentScrollTop + 15;

    // Update the flag to show/hide the Scroll to Bottom button
    this.showScrollToBottom = !atBottom;

    if (atBottom) {
      this.autoScrollEnabled = true;
    }

    // Update the last scroll position
    this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For negative scrolling
  }

  OnScrollToBottom() {
    setTimeout(() => {
      this.autoScrollEnabled = true;
    }, 590);
    this.scrollToBottomSlow();
  }

  scrollToBottomSlow() {
    const container = this.chatContainer.nativeElement;
    const target = container.scrollHeight; // Total scrollable height
    const start = container.scrollTop; // Current scroll position
    const distance = target - start; // Distance to scroll
    const duration = 600; // Duration of the scroll in milliseconds

    let startTime: number | null = null;

    const animation = (currentTime: number) => {
      if (startTime === null) startTime = currentTime; // Initialize start time
      const timeElapsed = currentTime - startTime; // Time elapsed since start
      const progress = Math.min(timeElapsed / duration, 1); // Normalize progress (0 to 1)

      // Easing function for smooth scrolling
      const ease = easeInOutQuad(progress);
      container.scrollTop = start + distance * ease; // Update scroll position

      if (timeElapsed < duration) {
        requestAnimationFrame(animation); // Continue the animation
      } else {
        this.showScrollToBottom = false; // Hide the button after scrolling
      }
    };

    requestAnimationFrame(animation); // Start the animation

    // Easing function: ease in-out
    function easeInOutQuad(t: number) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }
  }

  openFeedbackLikeModel(index: number, botRes: string) {
    if (this.appcomponentservice.isModelOpen) {
      this.modalService.close();
      this.appcomponentservice.setIsModelOpen(false);
    }
    this.appcomponentservice.setIsModelOpen(true);
    this.isLiked = true;
    this.likedIndex = index;
    this.dislikedIndex = -1;
    this.likedIndices.add(index);
    this.dislikedIndices.delete(index);
    this.disabledLikeIndices.add(index);
    var FeedbackLike = {
      convid: this.guid,
      userid: this.userId,
      name: this.userName,
      userQuery: this.userQuery,
      apiResponse: botRes,
      gptModel: this.appcomponentservice.systemModel,
      isDocAnalyzerActive: false,
      isEFSActive: false,
      document_Name: [],
    };

    this.modalService.open(FeedbackLikeComponent, { lstlikeOptions: FeedbackLike }).onClosed().subscribe(res => {
      this.appcomponentservice.setIsModelOpen(false);
      // const likeButton = document.querySelector('.likeButton');
      //   if (likeButton) {
      //       likeButton.classList.add('green-like');
      //   }
      //   const dislikeButton = document.querySelector('.dislikeButton');
      // Check if the likeButton element exists
      // if (dislikeButton) {
      //     // Remove the likeButton element from the DOM
      //     dislikeButton.remove();
      // }
    });
  }

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.lblNameGreeting = "Bonjour";
            this.lblHelpGreeting = "Je suis Gen-D, votre assistant IA de Deloitte. Comment puis-je vous aider?";
            this.lblGenDIntro1 = "Gen-D est un outil d'IA qui vous aide à travailler plus rapidement. Demandez-moi quoi que ce soit – je ferai de mon mieux pour vous aider!";
            this.lblGenDIntro2 = "Nouveau sur Gen-D? Consultez l'introduction";
            this.lblPrivacyPolicy = "ICI";
            this.lblSamplePromptLine = "Commencez avec une requête modèle";
            this.lblSamplePrompt1 = "Comparez Agile, SAFe et présentez votre réponse sous forme de tableau.";
            this.lblSamplePrompt2 = "Pouvez-vous fournir un exemple d'agrégation et de résumé de données à l'aide de requêtes SQL?";
            this.lblSamplePrompt3 = "Fournissez des lignes directrices pour rédiger une proposition de projet efficace, y compris les sections clés et la structure recommandée.";
            this.lblAskQuestion = "Posez moi une question...";
            this.privacy_URL = "https://mydeloitte.portal.deloitte.ca/sites/resources/ActiveFR/Internal/QualityandRiskManagement/Privacy/Documents/AI%20Guidelines%20Approved%20AI%20Systems_FR.pdf";
            this.lblSorrySomething1 = "Oops, quelque chose ne va pas. Veuillez mettre à jour votre demande et réessayer. Si le problème persiste, veuillez vous rendre sur la ";
            this.lblSorrySomething2 = " et publier votre problème dans le canal 'Discussion'. Notre équipe s'occupera de ce problème dès que possible.";
            this.lblUserCommunity = "Communauté d'utilisateurs";
            break;

          default:
            this.lblNameGreeting = "Hi";
            this.lblHelpGreeting = "I am Gen-D, your Deloitte AI companion. How can I help you today?";
            this.lblGenDIntro1 = "Gen-D is an AI tool that helps you work faster. Ask me anything - I’ll do my best to help!";
            this.lblGenDIntro2 = "New to Gen-D? Check out the introduction";
            this.lblPrivacyPolicy = "HERE";
            this.lblSamplePromptLine = "Start with a sample prompt";
            this.lblSamplePrompt1 = "Compare Agile, SAFe and present your response in a tabular form.";
            this.lblSamplePrompt2 = "Can you provide an example of how to aggregate and summarize data using SQL queries?";
            this.lblSamplePrompt3 = "Provide guidelines for writing an effective project proposal, including key sections and recommended structure.";
            this.lblAskQuestion = "Ask me a question...";
            this.privacy_URL = "https://mydeloitte.portal.deloitte.ca/sites/resources/ActiveEN/Internal/QualityandRiskManagement/Privacy/Documents/AI%20Guidelines%20Approved%20AI%20Systems_EN.pdf";
            this.lblSorrySomething1 = "Oops, something is wrong. Please update your prompt and try again. If the issue persists, please head to the ";
            this.lblSorrySomething2 = " and post your issue in the 'Discussion' channel. Our team will attend the issue asap.";
            this.lblUserCommunity = "User Community";
            break;
        }
        // alert(locale)
      });
  }

  openUserCommunityLink(): void {
    window.open(this.teamsChannelUrl, '_blank');
  }

  ngOnInit() {
    this.messages.next([]);
    this.loadingService.setLoadingState(false); // Set loading to true
    this.appcomponentservice.setIsNewChatVisible(true);
    this.loadingService.setstopstreaming(false);
    this.resetSubscription = this.resetChatService.reset$.subscribe(() => {
      this.resetVariables();
    });
    this.isDefaultText = true;
    // Subscribe to the service and call the function when the event is triggered
    this.appcomponentservice.triggerNewChatFunction$.subscribe(() => {
      this.newChat();
    });
    this.getProfile();
    this.manageLocale();
    this.convId = this.appcomponentservice.conversationData;
    if (this.appcomponentservice.conversationData) {
      this.convId = this.appcomponentservice.conversationData;
      this.guid = this.appcomponentservice.conversationData;
      this.fetchConversationData(this.convId);
    }
    
    this.conversationSubscription = this.appcomponentservice.conversationData$.subscribe(convId => {
      if (this.appcomponentservice.conversationData) {
        this.convId = this.appcomponentservice.conversationData;
        this.guid = this.appcomponentservice.conversationData;
        this.fetchConversationData(this.convId);
      }
    });

    // this.routerSubscription = this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.convId = this.appcomponentservice.conversationData;
    //     this.fetchConversationData(this.convId);
    //   }
    // });
  }
 
  fetchConversationData(convId:any){
    if(convId){
      
      this.resetVariables();
      this.conversationmessages = true;
     // this.stopStreaming = true;
      this.loadingService.setLoadingState(false); 
      this.messages.next([]);
      this.tempMessages = []
      this.isDefaultText = false; 
      this.conHistoryService.chatHistoryId(convId)
      .subscribe(
        res => {
          console.log("res query assistant", res)
         res.response.reverse().forEach((element) => {
                this.messages.next([...this.messages.value, { user: 'user', text: element.query }]);
                this.messages.next([...this.messages.value, { user: 'bot', text: element.assitant }]);
                this.tempMessages.push({ user: 'user', text: element.query });
                this.tempMessages.push({ user: 'user', text:  element.assitant  });

         });
         setTimeout(() => {
          this.ensurePreTagHeaders();
         }, 100);
      },
        (error) => {
          
        });
    }
  }

  ngOnDestroy() {
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.conversationSubscription) {
      this.conversationSubscription.unsubscribe();
    }
  }

  // Method to reset all variables to their default values
  resetVariables() {
    this.likedIndex = -1;
    this.dislikedIndex = -1;
    this.isLiked = false;
    this.isDisLiked = false;
    this.likedIndices = new Set();
    this.dislikedIndices = new Set();
    this.disabledLikeIndices = new Set<number>();
    this.disabledDisLikeIndices = new Set<number>();
  }


  defaultPrompt(msg) {
    this.userPrompt = msg;
    setTimeout(() => {
      this.adjustTextareaHeight(this.dynamicTextarea.nativeElement);
    });

  }

  resetButtonStates(index: number) {
    this.likedIndices.delete(index);
    this.dislikedIndices.delete(index);
  }

  async sendMessage(regenerateFlag: boolean, responsetext: any) {
    if (!regenerateFlag) {
      if (this.userPrompt.trim() == "" || this.disableSendButton)
        return;
    }
    this.conversationmessages = false;
    this.stopStreaming = false;
    this.errorMesg = false;
    this.isStreaming = true;
    this.loadingService.setstopstreaming(true);
    this.disableSendButton = true;
    this.appcomponentservice.setIsSendButtonDisabled(true);
    this.loadingService.setLoadingState(true); // Set loading to true
    this.isDefaultText = false;
    var tempPrompt = this.userPrompt.trim();
    this.userQuery = this.userPrompt;
    this.userPrompt = "";
    const textarea = this.textareaRef;
    this.resetTextareaHeight(); // Reset height after message send
    if (this.autoScrollEnabled) {
      this.scrollToBottom();
    }
    if (regenerateFlag) {
      // const messageToRemove = { user: 'bot', text: responsetext };
      // this.messages.next(this.messages.value.filter(message => !(message.user === messageToRemove.user && message.text === messageToRemove.text)));
      // tempPrompt =  this.userResponse ;
      const messageToRemove = { user: 'bot', text: responsetext };

      // Find the index of the last occurrence of the message with the same user and text
      const lastIndex = this.messages.value
        .slice()  // Create a copy of the array so we don't modify the original array during iteration
        .reverse()  // Reverse the array to start looking from the end
        .findIndex(message => message.user === messageToRemove.user && message.text === messageToRemove.text);

      if (lastIndex !== -1) {
        // Get the actual index in the original array (since we reversed it)
        const actualIndex = this.messages.value.length - 1 - lastIndex;

        // Create a copy of the messages array and remove the message at the found index
        const updatedMessages = [...this.messages.value];
        updatedMessages.splice(actualIndex, 1); // Remove the message at the found index

        // Update the messages stream with the updated array
        this.messages.next(updatedMessages);
      }
      //find last message from tempMessages array in regenrate flag and removing it from the tempMessages array using splice
      const lastMessageIndex = this.tempMessages.length - 1;
      this.tempMessages.splice(lastMessageIndex, 1);
      // Update tempPrompt as required
      tempPrompt = this.userResponse;
    }
    else {
      if (tempPrompt == undefined || tempPrompt.trim() == "") {
        return;
      }
      else {
        this.messages.next([...this.messages.value, { user: 'user', text: tempPrompt }]);
        this.tempMessages.push({ user: 'user', text: tempPrompt });
        // this.inputMessage = text + this.userQueryLength;;
      }
      //return;
    }
    this.loadingService.setUserMessage(tempPrompt);

    this.chatService.getSearchRes(tempPrompt.trim(), this.appcomponentservice.systemModel, this.appcomponentservice.systemSettings.systemMessage,
      this.appcomponentservice.systemSettings.responseStyle, this.guid, regenerateFlag, this.appcomponentservice.systemSettings.userQueryLength)
      .subscribe((response) => {
        if(response.content === null || response.content === ""){
          this.appcomponentservice.setIsSendButtonDisabled(false);
          this.loadingService.setLoadingState(false);
          this.loadingService.setstopstreaming(false);
          this.errorMesg = true;
          this.disableSendButton = false;
          return;
        }
        else{
          this.loadingService.setLoadingState(false); // Set loading to false
          this.tempMessages.push({ user: 'bot', text: response.content }); // Add the bot response to the tempMessages array for copy functionality
        var parsedText = this.markdownService.parse(response.content);
        var customizedContent = this.customizeMarkDown(parsedText).then((res) => {
          this.simulateStreaming(res).then(() => {
            this.bindActionToCopyIcon();
            //this.customizeHyperlinks();
            if (this.autoScrollEnabled) {
              this.scrollToBottom();
            }
            this.disableSendButton = false;
            this.appcomponentservice.setIsSendButtonDisabled(false);
            this.isStreaming = false;
            this.loadingService.setstopstreaming(false);
           // this.appcomponentservice.setConversationId(this.guid);
          })
        });
       }
      },
        (error) => {
          this.appcomponentservice.setIsSendButtonDisabled(false);
          this.loadingService.setLoadingState(false);
          this.errorMesg = true;
          this.disableSendButton = false;
          this.loadingService.setstopstreaming(false);
        })
  }
  async customizeMarkDown(htmlContent): Promise<any> {
    // Parse the HTML string into a DOM
    const parser = new DOMParser();
    const resolvedHtmlContent = await (typeof htmlContent === 'string' ? htmlContent : await htmlContent);
    const doc = parser.parseFromString(resolvedHtmlContent, 'text/html');
    //const preTagRegex = /<pre.*?>(.*?)<\/pre>/gs;  // Regular expression to match <pre> tags
    const preTagRegex = /<pre[^>]*>.*?<\/pre>/gs;
    const preTags = [...htmlContent.matchAll(preTagRegex)];

    // Step 1: Customize <a> tags using regex
    const aTagRegex = /<a\b([^>]*)href="([^"]+)"([^>]*)>/gi;
    htmlContent = htmlContent.replace(aTagRegex, (match, beforeHref, href, afterHref) => {
      const hasTarget = /target\s*=\s*"_blank"/i.test(match);
      const hasRel = /rel\s*=\s*".*?noopener.*?"/i.test(match);

      let newAttributes = `${beforeHref}href="${href}"${afterHref}`;
      if (!hasTarget) {
        newAttributes += ` target="_blank"`;
      }
      // if (!hasRel) {
      //   newAttributes += ` rel="noopener noreferrer"`;
      // }

      return `<a${newAttributes}>`;
    });


    // Iterate through each <pre> match and insert the <div> tag based on its index
    let updatedHtmlContent = htmlContent;

    preTags.forEach((match, index) => {
      const preContent = match[0]; // Full <pre> tag content
      const codeTagClassRegex = /<code class="([^"]+)"/; // Regex to extract class from <code>
      const codeClassMatch = preContent.match(codeTagClassRegex);
      const codeClass = codeClassMatch ? codeClassMatch[1] : "";
      const classSplit = codeClass.split('-');
      if (codeClass !== null && codeClass !== undefined && codeClass !== '') {
        const langName =
          classSplit.length > 1 &&
            classSplit[1] &&
            classSplit[1] !== 'none' &&
            classSplit[1] !== 'undefined'
            ? classSplit[1]
            : 'Plain Text';

        //  const newDivTag = `<div class="preTagHeader"><span class="clsLanguage text-left">${langName}</span><a class="clsCopy clsCopyTag`+ index + ` text-right"><i class="fa fa-clone clsCopyIcon clsCopyIcon` + index + `"></i></a></div>`;
        // Insert the new <div> tag
        const newDivTag = `<div class="preTagHeader">
                          <span class="clsLanguage text-left">${langName}</span>
                          <a class="clsCopy text-right"><i class="fa fa-clone"></i></a>
                        </div>`;
        updatedHtmlContent = updatedHtmlContent.replace(match[0], (preMatch) => {
          // Insert the new <div> tag between <pre> and <code> tags
          return preMatch.replace('<pre>', `<pre>${newDivTag}`);
        });
      }
    });
    return updatedHtmlContent;
  }
  bindActionToCopyIcon() {
    setTimeout(() => {
      const preTags = this.el.nativeElement.querySelectorAll('pre');
      preTags.forEach((preTag: HTMLElement, idx: any) => {
        // Only add the header if it doesn't already exist
        const existingHeader = preTag.querySelector('.preTagHeader');
        if (existingHeader) {
          const copyTag = preTag.querySelector('.clsCopy');
          if (copyTag) {
            const copyIconTag = preTag.querySelector('.copyIconCustomized');
            if (!copyIconTag) {
              var copyFavIcon = copyTag.querySelector('i');
              if (copyFavIcon)
                this.render.addClass(copyFavIcon, 'clsCopyIcon' + idx)
              this.render.addClass(copyTag, 'copyIconCustomized');
              this.render.listen(copyTag, 'click', () => this.copyCodeBlock(preTag, idx));
            }
          }
        }
      });
    }, 100);
  }

  customizeHyperlinks() {
    // customize a tags for to target blank with in the markdown content
    const markdownContent = this.el.nativeElement.querySelector('markdown');
    if (markdownContent) {
      const aTags = markdownContent.querySelectorAll('a'); // Get all <a> tags inside <markdown>
      aTags.forEach((aTag: HTMLAnchorElement) => {
        const href = aTag.getAttribute('href'); // Check if the <a> tag has an href
        const target = aTag.getAttribute('target'); // Check if target already exists

        if (href) {
          // Add target="_blank" only if href exists and target is not already set
          aTag.setAttribute('target', '_blank');
        }
      });
    }
  }

  copyCodeBlock(preTag: HTMLElement, idx: number) {
    var codeTag = preTag.querySelector('code');
    if (codeTag != null) {
      const codeText = codeTag.innerText; // Extract the code text
      const textArea = document.createElement('textarea');
      textArea.value = codeText;
      textArea.style.position = 'fixed';  // Avoid page scroll
      document.body.appendChild(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999); // For mobile devices
      const successful = document.execCommand('copy');
      document.body.removeChild(textArea);

      var copyIcon = document.querySelector('.clsCopyIcon' + idx) as HTMLElement;
      if (copyIcon) {
        copyIcon.classList.remove('fa-clone');
        copyIcon.classList.add('fa-check');
        copyIcon.classList.add('icontick');
        setTimeout(() => {
          copyIcon.classList.remove('fa-check');
          copyIcon.classList.remove('icontick');
          copyIcon.classList.add('fa-clone');
        }, 5000);
      }
    }

  }

  simulateStreaming(message: string): Promise<void> {
    return new Promise((resolve) => {
      const chunkSize = 10; // Adjust chunk size as needed
      let index = 0;

      const addChunk = () => {
        if (index < message.length) {
          const isBotMessage =
            this.messages.value.length > 0 &&
            this.messages.value[this.messages.value.length - 1].user === 'bot';

          this.messages.next(
            isBotMessage
              ? [
                ...this.messages.value.slice(0, -1),
                {
                  ...this.messages.value[this.messages.value.length - 1],
                  text:
                    this.messages.value[this.messages.value.length - 1].text +
                    message.substring(index, index + chunkSize),
                },
              ]
              : [
                ...this.messages.value,
                {
                  user: 'bot',
                  text: message.substring(index, index + chunkSize),
                },
              ]
          );

          // Call `ensurePreTagHeaders` after updating the message only once
          // setTimeout(() => this.ensurePreTagHeaders(), 0);

          // scroll to bottom
          if (this.autoScrollEnabled) {
            this.scrollToBottom();
          }

          index += chunkSize;

          of(null)
            .pipe(
              delay(50), // Adjust delay as needed
              concatMap(() => of(null))
            )
            .subscribe(addChunk);
        } else {
          resolve();
        }
      };

      addChunk();
    });
  }

  setUserMessage(text: string) {
    this.userResponse = text;
  }
  ensurePreTagHeaders(): void {
    // Scroll to the bottom if auto-scrolling is enabled
    if (this.autoScrollEnabled) {
      this.scrollToBottom();
    }


    const preTags = this.el.nativeElement.querySelectorAll('pre');

    preTags.forEach((preTag: HTMLElement, idx: any) => {
      // Only add the header if it doesn't already exist
      const existingHeader = preTag.querySelector('.preTagHeader');
      if (!existingHeader) {
        const codeTag = preTag.querySelector('code');
        if (codeTag?.className) {
          const classSplit = codeTag.className.split('-');
          const langName =
            classSplit.length > 1 &&
              classSplit[1] &&
              classSplit[1] !== 'none' &&
              classSplit[1] !== 'undefined'
              ? classSplit[1]
              : 'Plain Text';

          const headerDiv = this.render.createElement('div');
          this.render.addClass(headerDiv, 'preTagHeader');

          const leftSpan = this.render.createElement('span');
          this.render.addClass(leftSpan, 'clsLanguage');
          this.render.addClass(leftSpan, 'text-left');
          const leftText = this.render.createText(langName);
          this.render.appendChild(leftSpan, leftText);

          const rightSpan = this.render.createElement('a');
          this.render.addClass(rightSpan, 'clsCopy');
          this.render.addClass(rightSpan, 'text-right');
          const icon = this.render.createElement('i');
          this.render.addClass(icon, 'fa');
          this.render.addClass(icon, 'fa-clone');
          this.render.addClass(icon, 'clsCopyIcon' + idx);
          this.render.appendChild(rightSpan, icon);


          this.render.appendChild(headerDiv, leftSpan);
          this.render.appendChild(headerDiv, rightSpan);

          this.render.insertBefore(preTag, headerDiv, codeTag);

          this.render.listen(rightSpan, 'click', () => this.copyCodeBlock(preTag, idx));
        }
      }
    });
  }



  copyToClipboard(text: string, index: any) {
    this.isCopy = true;
    this.copyIndex = index;
    var plainText = this.tempMessages[index].text;
    this.tempCopiedText = plainText;
      setTimeout(() => {
        var tempMarkDown = document.getElementById('tempMarkDown');
        plainText = tempMarkDown.innerText;
        const textArea = document.createElement('textarea');
        textArea.value = plainText;
        textArea.style.position = 'fixed';  // Avoid page scroll
        document.body.appendChild(textArea);
        textArea.select();
        textArea.setSelectionRange(0, 99999); // For mobile devices
        const successful = document.execCommand('copy');
        document.body.removeChild(textArea);
      }, 100);
    setTimeout(() => {
      this.isCopy = false;
    }, 3000);
  }
  ngAfterViewInit() {
    const textarea = this.dynamicTextarea.nativeElement;
    this.defaultHeight = textarea.scrollHeight + 1.5; // Default height is based on initial content (before typing)
    // Ensure it starts with the correct height
    textarea.style.height = `${this.defaultHeight}px`;
  }
  resetTextareaHeight(): void {
    const textarea = this.dynamicTextarea.nativeElement;
    if (!textarea) return;

    // Reset height to the default height
    textarea.style.height = `${this.defaultHeight}px`;
  }

  isAtBottom(container: HTMLElement): boolean {
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const currentScrollTop = container.scrollTop;

    // Check if the user is at the bottom, allowing for a small threshold (e.g., 15px)
    return scrollHeight - clientHeight <= currentScrollTop + 15;
  }

  adjustTextareaHeight(textarea: HTMLTextAreaElement): void {


    if (!textarea) return;

    const atBottom = this.isAtBottom(this.chatContainer.nativeElement);

    // Reset height to 'auto' so that the textarea can shrink
    textarea.style.height = 'auto';

    // Calculate the new height based on the content's scrollHeight
    const maxHeight = 100; // Max height for the textarea
    const newHeight = Math.min(textarea.scrollHeight + 1.5, maxHeight); // Ensure we do not exceed the max height

    // Set the height to the new height
    textarea.style.height = `${newHeight}px`;

    if (atBottom) {
      this.scrollToBottom();
    }
  }

  updateChatContainerHeight(messageBoxHeight: number): void {
    const wrapperHeight = window.innerHeight;
    const chatContainerHeight = wrapperHeight - messageBoxHeight - 50; // Adjusting for any extra space like margins or padding
    this.render.setStyle(this.chatContainer.nativeElement, 'height', `${chatContainerHeight}px`);
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        const chatContainer = this.chatContainer.nativeElement as HTMLElement;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }, 0);
    } catch (err) {
      console.error('Scroll to bottom failed:', err);
    }

  }

  getProfile() {
    this.https.get(GRAPH_ENDPOINT)
      .subscribe((profile: any) => {
        this.userName = profile.displayName;
        this.userId = profile.mail;
        this.displayName = profile.givenName;
      })
  }

  // Handle Enter and Shift + Enter key events
  handleKeydown(event: KeyboardEvent, textarea: HTMLTextAreaElement): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      // If Enter is pressed without Shift, call sendMessage function
      if (this.userPrompt.trim() === "" || this.disableSendButton) {
        event.preventDefault(); // Prevent the default action of adding a newline
        return;
      }
      this.sendMessage(false, "");
      event.preventDefault(); // Prevent the default action of adding a newline
    }
    
  }

  newChat() {
    // if(this.errorMesg || this.loadingService.isLoading){
    //   if (this.disableSendButton)
    //   return;
    //   }
   // this.loadingService.setUserMessage(this.userPrompt);
   //this.stopStreaming = true;
   // this.newchat = true;
    if(this.disableSendButton || this.loadingService.isLoading){
        return;
    }
    this.errorMesg = false;
    this.isDefaultText = true;
    this.autoScrollEnabled = true;
    this.messages.next([]);
    this.guid = uuidv4();
    this.userPrompt = "";
    this.resetTextareaHeight(); // Reset height after message send
    this.tempMessages = [];
   // this.loadingService.setUserMessage(this.userPrompt);

    //this.loadingService.setLoadingState(false); 
  }
}
//test

