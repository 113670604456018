import { ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, concatMap, delay, of, takeUntil } from 'rxjs';
import { ChatService } from '../Services/chat.service';
import { v4 as uuidv4 } from 'uuid';
import { LoadingService } from '../Services/LoadingService';
import { AppComponentService } from '../Services/appcomponentservice';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { DocumentUploadService } from '../Services/document-upload.service';
import { FeedbackDisLikeComponent } from '../feedback-dis-like/feedback-dis-like.component';
import { FeedbackLikeComponent } from '../feedback-like/feedback-like.component';
import { HttpClient } from '@angular/common/http';
import { Themes, Size, WidthState, ButtonKind } from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { ResetBlobStorageService } from '../Services/reset-blob-storage.service';
import { ResetChatService } from '../Services/reset-chat.service';
import { RecentAgentsService } from '../Services/recent-agent.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import * as moment from 'moment';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
@Component({
  selector: 'app-efs',
  templateUrl: './efs.component.html',
  styleUrls: ['./efs.component.css']
})
export class EfsComponent {
  // New props
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileInput2') fileInput2: ElementRef;
  private resetSubscription: Subscription;
  // Conditional
  isProcessingCompleted: boolean = false;
  selectedFiles: File[] = [];
  isUploadLimitExceeded: boolean = false; // Flag for upload limit exceeded
  MAX_FILE_SIZE = 50 * 1024 * 1024; // Max total file size in bytes (50 MB)
  MAX_FILE_COUNT = 5; // Max number of files
  cancelButtonKind = ButtonKind.secondaryLoud;
  primaryButtonTheme = Themes.green;
  primaryButtonKind = ButtonKind.primaryLoud;
  allowedTypes: string[] = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'application/pptx',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/VTT', 'text/vtt',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/html'
  ];
  private teamsChannelUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';
  private destroy = new Subject<void>();
  lblNameGreeting: string;
  lblHelpGreeting: string;
  lblHiName: string;
  lblGenDIntro1: string;
  lblGenDIntro2: string;
  lblEFS_01: string;
  KB_URL: string;
  lblAskQuestion: string;

  txtUploadDocs: string;
  lblDragHere: string;
  txtYouCanUpload: string;
  lblStartProcessing: string;
  txtReachedUploadLimit: string;
  btnStartProcessing: string;
  btnReset: string;
  lblAskFirstQues: string;

  userName: any;
  feedbacklike: any;
  userQuery: string;
  isCopy: boolean;
  userResponse: string;
  isStreaming: boolean;
  dragDropElement: any;
  dragDropEnabled: any;
  FileNames: string[] = [];
  errorCount: any;
  gotSummaryResp: boolean;
  disableProcessing: boolean = false;
  errorMesg: any;
  references: any;
  docerrorMessage: boolean = false;
  ErrorMessage: string;
  docStatusMessage: any;
  StatusMessage: any;
  StatusMessageUpload: string;
  StatusMessageProcess: string;
  StatusMessagePrepare: string;
  loadmsgprc: any;
  loadmsgEmb: any;
  loadmsgSumry: any;
  resetDisabled: boolean = true;
  displayName: any;
  lblSorrySomething2: string;
  lblSorrySomething1: string;
  lblUserCommunity: string;
  verifyEFSvalue: boolean = true;
  copyIndex: any;
  typesToUpload: string;
  constructor(private modalService: ModalService, private chatService: ChatService, public loadingService: LoadingService,
    public appcomponentservice: AppComponentService, private render: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef,
    private https: HttpClient, private DocumentUploadService: DocumentUploadService, private i18n: I18nService,
    private ResetBlobStorageService: ResetBlobStorageService, private resetChatService: ResetChatService, private recentAgentsService: RecentAgentsService, private router: Router,
    private chatservice: ChatService) {

  }

  isLoading = false;
  disableSendButton = false;
  isDefaultText: boolean;
  userPrompt: string;
  systemModel = "GPT 3.5"
  systemMessage: string;
  responseStyle: string;
  guid = uuidv4()
  userId: string;
  regenerateFlag = false;
  userQueryLength;
  likedIndex: number = -1;
  dislikedIndex: number = -1;
  isLiked: boolean = false;
  isDisLiked: boolean = false;
  likedIndices: Set<number> = new Set();
  dislikedIndices: Set<number> = new Set();
  disabledLikeIndices = new Set<number>();
  disabledDisLikeIndices = new Set<number>();
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @ViewChild('dynamicTextarea') dynamicTextarea!: ElementRef;
  textareaRef: any;
  messages = new BehaviorSubject<{ user: string; text: string }[]>([
  ]);
  messages$ = this.messages.asObservable();
  private defaultHeight: number = 50.5;

  autoScrollEnabled: boolean = true;
  lastScrollTop: number = 0;
  showScrollToBottom: boolean = false;
  lblIndexLastRefreshDate: string;
  // New methods for file upload

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }
  onFileSelected(event: any) {
    const validFiles = this.getValidFiles(event.target.files);
    if (validFiles) {
      const nonDuplicateFiles = validFiles.filter((file: File) =>
        !this.selectedFiles.some((selectedFile: File) => selectedFile.name === file.name)
      );
      if (nonDuplicateFiles.length > 0) {
        this.appendValidFiles(this.selectedFiles, nonDuplicateFiles);
        this.updateUploadLimitFlag();
        this.gotSummaryResp = false;
      }
    }
    if (this.selectedFiles.length > 0) {
      this.isDefaultText = false;
      this.disableProcessing = false;
      this.appcomponentservice.setIsSendButtonDisabled(false);
      this.loadingService.setLoadingState(false);
      this.messages.next([]);
      this.resetDisabled = true;
    }
  }
  onFileDropped(event: DragEvent) {
    event.preventDefault();
    const validFiles = this.getValidFiles(event.dataTransfer.files)
    if (validFiles) {
      const nonDuplicateFiles = validFiles.filter((file: File) =>
        !this.selectedFiles.some((selectedFile: File) => selectedFile.name === file.name)
      );
      if (nonDuplicateFiles.length > 0) {
        this.appendValidFiles(this.selectedFiles, nonDuplicateFiles);
        this.updateUploadLimitFlag();
        this.gotSummaryResp = false;
        this.fileInput.nativeElement.value = '';
      }
    } if (this.selectedFiles.length > 0) {

      this.isDefaultText = false;
      this.disableProcessing = false;
      this.appcomponentservice.setIsSendButtonDisabled(false);
      this.loadingService.setLoadingState(false);
      this.messages.next([]);
      this.resetDisabled = true;
    }

  }

  removeFile(file: File): void {
    this.selectedFiles = this.selectedFiles.filter(f => f !== file);
    this.updateUploadLimitFlag();
  }

  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }
  triggerFileUpload2() {
    this.fileInput2.nativeElement.click();
  }

  onScroll(event: Event): void {
    const container = this.chatContainer.nativeElement;
    const currentScrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    // Check if user is scrolling down or up
    if (currentScrollTop < this.lastScrollTop) {
      this.autoScrollEnabled = false;
    }

    // Determine if the user is at the bottom
    const atBottom = scrollHeight - clientHeight <= currentScrollTop + 15;

    // Update the flag to show/hide the Scroll to Bottom button
    this.showScrollToBottom = !atBottom;

    if (atBottom) {
      this.autoScrollEnabled = true;
    }

    // Update the last scroll position
    this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For negative scrolling
  }

  OnScrollToBottom() {
    setTimeout(() => {
      this.autoScrollEnabled = true;
    }, 590);
    this.scrollToBottomSlow();
  }

  scrollToBottomSlow() {
    const container = this.chatContainer.nativeElement;
    const target = container.scrollHeight; // Total scrollable height
    const start = container.scrollTop; // Current scroll position
    const distance = target - start; // Distance to scroll
    const duration = 600; // Duration of the scroll in milliseconds

    let startTime: number | null = null;

    const animation = (currentTime: number) => {
      if (startTime === null) startTime = currentTime; // Initialize start time
      const timeElapsed = currentTime - startTime; // Time elapsed since start
      const progress = Math.min(timeElapsed / duration, 1); // Normalize progress (0 to 1)

      // Easing function for smooth scrolling
      const ease = easeInOutQuad(progress);
      container.scrollTop = start + distance * ease; // Update scroll position

      if (timeElapsed < duration) {
        requestAnimationFrame(animation); // Continue the animation
      } else {
        this.showScrollToBottom = false; // Hide the button after scrolling
      }
    };

    requestAnimationFrame(animation); // Start the animation

    // Easing function: ease in-out
    function easeInOutQuad(t: number) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    }
  }
  openFeedbackDislikeModel(index, botRes) {
    this.appcomponentservice.setIsModelOpen(true);
    this.isDisLiked = true;
    this.dislikedIndex = index;
    this.likedIndex = -1;
    this.dislikedIndices.add(index);
    this.likedIndices.delete(index);
    this.disabledDisLikeIndices.add(index);
    var FeedbackDisLike = {
      // convid: this.guid,
      userid: this.userId,
      name: this.userName,
      // document_Name: this.selectedFiles.map(file => file.name),
      kbName: "efs-testing",
      kbid: "KM072",
      indexId: "b2a24078-25db-4cce-a9a0-1bb30ae74381",
      apiResponse: botRes,
      userQuery: this.userQuery,
      // isDocAnalyzerActive: true,
      isEFSActive: true,
    };
    this.modalService.open(FeedbackDisLikeComponent, { lstoptionDislike: FeedbackDisLike, }).onClosed().subscribe(res => {
      this.appcomponentservice.setIsModelOpen(false);
    });
  }

  doLiked(index: number): boolean {
    return this.likedIndices.has(index);
  }
  doDisliked(index: number): boolean {
    return this.dislikedIndices.has(index);
  }

  openFeedbackLikeModel(index: number, botRes: string) {
    this.appcomponentservice.setIsModelOpen(true);
    this.isLiked = true;
    this.likedIndex = index;
    this.dislikedIndex = -1;
    this.likedIndices.add(index);
    this.dislikedIndices.delete(index);
    this.disabledLikeIndices.add(index);
    var FeedbackLike = {
      // convid: this.guid,
      userid: this.userId,
      name: this.userName,
      // document_Name: this.selectedFiles.map(file => file.name),
      kbName: "efs-testing",
      kbid: "KM072",
      indexId: "b2a24078-25db-4cce-a9a0-1bb30ae74381",
      apiResponse: botRes,
      userQuery: this.userQuery,
      // isDocAnalyzerActive: true,
      isEFSActive: true,

    };

    this.modalService.open(FeedbackLikeComponent, { lstlikeOptions: FeedbackLike }).onClosed().subscribe(res => {
      this.appcomponentservice.setIsModelOpen(false);
      // const likeButton = document.querySelector('.likeButton');
      //   if (likeButton) {
      //       likeButton.classList.add('green-like');
      //   }
      //   const dislikeButton = document.querySelector('.dislikeButton');
      // Check if the likeButton element exists
      // if (dislikeButton) {
      //     // Remove the likeButton element from the DOM
      //     dislikeButton.remove();
      // }
    });
  }
  resetDocuments() {
    this.resetVariables();
    this.autoScrollEnabled = true;

    if (!this.gotSummaryResp) {

    }
    else {
      if (this.selectedFiles.length != 0) {
        this.ResetBlobStorageService.resetUploadedDocument().subscribe(
          (response: any) => {
          },
          (error: any) => {
            console.log('File upload failed.', error);
          });
      }
    }
    this.selectedFiles = [];
    this.resetTextareaHeight();
    this.disableSendButton = true;
    this.isDefaultText = true;
    this.appcomponentservice.setIsSendButtonDisabled(false);
    this.loadingService.setLoadingState(false);
    this.messages.next([]);
    this.resetDisabled = true;
    this.docerrorMessage = false;
    this.userPrompt = "";
    this.fileInput.nativeElement.value = '';
  }
  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        this.getKBChecks();
        switch (locale) {
          case "FR":
            this.lblNameGreeting = "Bonjour";
            this.lblHelpGreeting = "Je suis l'agent EFS, votre expert pour tout ce qui a trait à EFS. Comment puis-je vous aider aujourd'hui?";
            this.lblGenDIntro1 = "FR Gen-D is an AI tool that helps you work faster. Ask me anything - I’ll do my best to help!";
            this.lblGenDIntro2 = "Conteneur de données mis à jour: ";
            this.lblEFS_01 = "2 janvier 2025";


            this.KB_URL = environment.DMSKBURL; //"https://genai.deloitte.ca/km";
            this.lblAskQuestion = "Posez moi n'importe quelle question";


            this.lblHiName = "Bonjour, Pradeep";
            this.txtUploadDocs = "Téléchargez des documents et je les analyserai pour vous !";
            this.lblDragHere = "Glissez ici ou cliquez pour télécharger";
            this.txtYouCanUpload = "Vous pouvez télécharger jusqu'à 5 documents ou un total de 50 Mo";
            this.typesToUpload = "Format de fichier pris en charge : PDF, DOCX, PPTX, TXT, XLSX, VTT, HTML";
            this.lblStartProcessing = "Téléchargez vos fichiers et cliquez sur 'Démarrer le traitement'. Nous nous occuperons du prétraitement en arrière-plan pendant environ 3 à 5 minutes. Une fois terminé, le champ de saisie du chat sera déverrouillé et vous pourrez commencer à discuter avec votre document.";
            this.ErrorMessage = "Désolé, une erreur s'est produite. Veuillez réessayer ultérieurement."; //Gen-D Translations above 1 and below 3 too
            this.StatusMessageUpload = "Téléchargement des fichiers!";
            this.StatusMessageProcess = "Traitement des fichiers, veuillez patienter!";
            this.StatusMessagePrepare = "Préparation du résumé, veuillez patienter!";
            this.txtReachedUploadLimit = "Vous avez atteint votre limite de téléchargement.";
            this.btnStartProcessing = "Commencer le traitement";
            this.btnReset = "Réinitialiser";
            this.lblAskFirstQues = "Posez votre première question sur vos fichiers téléchargés pour commencer";
            this.lblSorrySomething1 = "Oops, quelque chose ne va pas. Veuillez réessayer. Si le problème persiste, veuillez vous rendre sur la ";
            this.lblSorrySomething2 = " et publier votre problème dans le canal 'Discussion'. Notre équipe s'occupera de ce problème dès que possible.";
            this.lblUserCommunity = "Communauté d'utilisateurs";
            this.lblIndexLastRefreshDate = "récupération..."
            break;

          default:
            this.lblNameGreeting = "Hi";
            this.lblHelpGreeting = "I am EFS agent, your EFS subject matter expert. How can I help you today?";
            this.lblGenDIntro1 = "Gen-D is an AI tool that helps you work faster. Ask me anything - I’ll do my best to help!";
            this.lblGenDIntro2 = "Data bucket updated: ";
            this.lblEFS_01 = "January 2nd, 2025";
            this.KB_URL = environment.DMSKBURL; //"https://genai.deloitte.ca/km";
            this.lblAskQuestion = "Ask me any question...";




            this.lblHiName = "Hi, Pradeep!";
            this.txtUploadDocs = "Upload Documents and I’ll analyze it for you!";
            this.lblDragHere = "Drag Here or click to upload ";
            this.txtYouCanUpload = "You can upload up to 5 documents or 50MB total in size";
            this.typesToUpload = "Supported file format: PDF, DOCX, PPTX, TXT, XLSX, VTT, HTML";
            this.lblStartProcessing = "Upload your files and click 'Start Processing'. We'll handle the preprocessing in the background for about 3-5 minutes. Once it's done, the chat input field will be unlocked, and you can start chatting with your document.";
            this.ErrorMessage = "Sorry, something went wrong. Please try again later.";
            this.StatusMessageUpload = "Uploading the Files!";
            this.StatusMessageProcess = 'Processing the Files, Please wait!';
            this.StatusMessagePrepare = "Preparing the Summary, Please wait!";
            this.txtReachedUploadLimit = "You have reached your upload limit.";
            this.btnStartProcessing = "Start Processing";
            this.btnReset = "Reset";
            this.lblAskFirstQues = "Ask your first question about your uploaded files to start";
            this.lblSorrySomething1 = "Oops, something is wrong. Please try again. If the issue persists, please head to the ";
            this.lblSorrySomething2 = " and post your issue in the 'Discussion' channel. Our team will attend the issue asap.";
            this.lblUserCommunity = "User Community";
            this.lblIndexLastRefreshDate = "retrieving..."
            break;
        }
        // alert(locale)
      });
  }


  ngOnInit() {
    this.recentAgentsService.addRecentPath(this.router.url);
    this.appcomponentservice.setIsNewChatVisible(true);
    // this.verifyEFSUSer();
    this.manageLocale();
    this.chatservice.verifyEFSUser().subscribe((response: any) => {
      if (response.result == "Failure") {
        this.router.navigate(['/error'])

      }
      else
        this.appcomponentservice.setIsEFSSUser(true);

    })
    this.isDefaultText = true;
    this.isProcessingCompleted = false;
    this.appcomponentservice.setIsSendButtonDisabled(false);
    this.loadingService.setLoadingState(false); // Set loading to true
    // Subscribe to the service and call the function when the event is triggered
    this.resetSubscription = this.resetChatService.reset$.subscribe(() => {
      this.resetVariables();
    });
    this.appcomponentservice.triggerNewChatFunction$.subscribe(() => {
      this.newChat();


    });
    this.getProfile();

  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
      this.loadingService.setLoadingState(false);
    }
  }
  resetVariables() {
    this.likedIndex = -1;
    this.dislikedIndex = -1;
    this.isLiked = false;
    this.isDisLiked = false;
    this.likedIndices = new Set();
    this.dislikedIndices = new Set();
    this.disabledLikeIndices = new Set<number>();
    this.disabledDisLikeIndices = new Set<number>();
  }
  defaultPrompt(msg) {
    this.userPrompt = msg;
    setTimeout(() => {
      this.adjustTextareaHeight(this.dynamicTextarea.nativeElement);
    });
  }



  async startProcessing(): Promise<any> {
    this.resetDisabled = true;
    this.docStatusMessage = true;
    this.StatusMessage = this.StatusMessageUpload;
    this.disableProcessing = true;
    this.isStreaming = true;
    if (this.autoScrollEnabled) {
      this.scrollToBottom();
    }
    this.appcomponentservice.setIsSendButtonDisabled(true);
    this.loadingService.setLoadingState(true);
    this.FileNames = [];
    const formData = new FormData();
    if (this.selectedFiles.length > 0) {
      this.selectedFiles.forEach(item => {
        console.log("this.item selected", item);
        formData.append('files', item, item.name);

      })
    }

    this.DocumentUploadService.uploadDocument(formData)
      .subscribe(
        (response: any) => {
          this.StatusMessage = this.StatusMessageProcess;
          this.DocumentUploadService.ProcessDocuments()
            .subscribe(
              async (response: any) => {
                this.StatusMessage = this.StatusMessagePrepare;
                this.DocumentUploadService.GenerateEmbeddings()
                  .subscribe(
                    (response: any) => {
                      this.DocumentUploadService.ShowSummary()
                        .subscribe(
                          async (response: any) => {
                            console.log("response.data", response.data)
                            this.gotSummaryResp = true;
                            //  if(response.data != []  )    {}
                            if (response.data.length != 0) {
                              let i = 0;
                              while (i < response.data.length) {
                                const item = response.data[i];
                                const title = item.title;
                                const summary = item.summary;
                                this.docStatusMessage = false;
                                this.loadingService.setLoadingState(false); // Set loading to false
                                await this.simulateStreaming("**" + title + "**" + " : " + summary + '\n \n').then(() => {                              //this.customizeMarkdownTag();
                                  this.cdr.detectChanges();
                                  if (this.autoScrollEnabled) {
                                    this.scrollToBottom();
                                  }
                                  this.appcomponentservice.setIsSendButtonDisabled(false);
                                  this.isStreaming = false;
                                });
                                i++;

                              }
                            }
                            else {
                              this.docStatusMessage = false;
                              this.loadingService.setLoadingState(false); // Set loading to false
                              await this.simulateStreaming("I apologize for the inconvenience, but I am unable to process the documents at the moment.").then(() => {                              //this.customizeMarkdownTag();
                                this.cdr.detectChanges();
                                if (this.autoScrollEnabled) {
                                  this.scrollToBottom();
                                }
                                this.appcomponentservice.setIsSendButtonDisabled(false);
                                this.isStreaming = false;
                              });
                            }
                            this.gotSummaryResp = true;
                            this.isProcessingCompleted = true;
                            this.disableSendButton = false;
                            this.resetDisabled = false;

                          },
                          (error: any) => {
                            this.docStatusMessage = false;
                            this.errorCount = this.errorCount + 1;
                            this.appcomponentservice.setIsSendButtonDisabled(false);
                            this.loadingService.setLoadingState(false); // Set loading to false
                            this.docerrorMessage = true;
                            this.resetDisabled = false;
                            this.errorMesg = true;
                          });
                    },
                    (error: any) => {
                      this.docStatusMessage = false;
                      this.errorCount = this.errorCount + 1;
                      this.appcomponentservice.setIsSendButtonDisabled(false);
                      this.loadingService.setLoadingState(false); // Set loading to false
                      this.docerrorMessage = true;
                      this.resetDisabled = false;
                      this.errorMesg = true;
                    });
              },
              (error: any) => {
                this.docStatusMessage = false;
                this.errorCount = this.errorCount + 1;
                this.appcomponentservice.setIsSendButtonDisabled(false);
                this.loadingService.setLoadingState(false);
                this.docerrorMessage = true;
                this.resetDisabled = false;
                this.errorMesg = true;
              });
        },

        (error: any) => {
          this.docStatusMessage = false;
          this.errorCount = this.errorCount + 1;
          this.appcomponentservice.setIsSendButtonDisabled(false);
          this.loadingService.setLoadingState(false);
          this.docerrorMessage = true;
          this.errorMesg = true;
        },
      );
  }


  async sendMessage(regenerateFlag: boolean, responsetext: any) {
    var tempPrompt = this.userPrompt.trim();
    this.userQuery = this.userPrompt.trim();

    if (tempPrompt == undefined || tempPrompt.trim() == "") {
      return;
    }
    else {
      this.resetDisabled = true;
      this.isStreaming = true;
      this.disableSendButton = true;
      this.isDefaultText = false;
      this.userPrompt = "";
      // this.adjustTextareaHeight(this.textareaRef);
      this.resetTextareaHeight();
      if (this.autoScrollEnabled) {
        this.scrollToBottom();
      }
      this.appcomponentservice.setIsSendButtonDisabled(true);
      this.loadingService.setLoadingState(true); // Set loading to true
      this.messages.next([...this.messages.value, { user: 'user', text: tempPrompt }]);
      // this.inputMessage = text + this.userQueryLength;;
    }

    this.chatService.getEFSData(tempPrompt.trim()).subscribe(async (response) => {
      console.log("response.content", response);
      let fullMessage = response.content;
      // if (response.references && response.references.length > 0) {
      //   this.references = response.references.map((reference: any) => (reference));
      //   fullMessage += '\n\n<b>References:</b> ' + this.references.join(', ');
      // }
      if (response.references && response.references.length > 0) {
        // Map over references and create a formatted string for each reference
        const uniqueSources = new Set(response.references.map((reference: any) => reference.source));

        // Convert the Set back to an array if you need to use it as an array
        this.references = Array.from(uniqueSources);
        // console.log("HEREEEE", this.references)
        // this.references = response.references.map((reference: any) => {
        //     // return `<b>${reference.page_content}</b> (Source: ${reference.source})`;
        //     return `${reference.source}`; });
        this.references.forEach((reference: any) => {
          return ` ${reference}`; // This will return each reference object


        });

        // Append the references to the full message
        fullMessage += "\n\n **References:** \n\n" + this.references.join('<br>');
      }
      this.loadingService.setLoadingState(false); // Set loading to false           
      await this.simulateStreaming(fullMessage).then(() => {
        // this.customizeMarkdownTag();
        this.cdr.detectChanges(); // Ensure DOM updates are detected (optional)
        if (this.autoScrollEnabled) {
          this.scrollToBottom();
        }
        this.disableSendButton = false;
        this.appcomponentservice.setIsSendButtonDisabled(false);
        this.isStreaming = false;
        this.resetDisabled = false;
      })
    },
      (error) => {
        this.appcomponentservice.setIsSendButtonDisabled(false);
        this.loadingService.setLoadingState(false);
        this.errorMesg = true;
        this.disableSendButton = false;
        this.loadingService.setstopstreaming(false);
      })
  }
  async simulateStreaming(message: string): Promise<void> {
    return new Promise((resolve) => {
      const chunkSize = 10; // Adjust chunk size as needed
      let index = 0;

      const addChunk = () => {
        if (index < message.length) {
          const isBotMessage =
            this.messages.value.length > 0 &&
            this.messages.value[this.messages.value.length - 1].user === 'bot';

          this.messages.next(
            isBotMessage
              ? [
                ...this.messages.value.slice(0, -1),
                {
                  ...this.messages.value[this.messages.value.length - 1],
                  text:
                    this.messages.value[this.messages.value.length - 1].text +
                    message.substring(index, index + chunkSize),
                },
              ]
              : [
                ...this.messages.value,
                {
                  user: 'bot',
                  text: message.substring(index, index + chunkSize),
                },
              ]
          );

          // Call `ensurePreTagHeaders` after updating the message only once
          setTimeout(() => this.ensurePreTagHeaders(), 0);


          if (this.autoScrollEnabled) {
            this.scrollToBottom();
          }

          index += chunkSize;

          of(null)
            .pipe(
              delay(50), // Adjust delay as needed
              concatMap(() => of(null))
            )
            .subscribe(addChunk);
        } else {
          resolve();
        }
      };

      addChunk();
    });
  }

  setUserMessage(text: string) {
    this.userResponse = text;
  }

  // verifyEFSUSer() {
  //   debugger;
  //   // this.verifyEFSvalue = true;
  //   // this.appcomponentservice.setIsModelOpen(false);
  //   // this.pinSidebar();
  //   this.chatService.verifyEFSUser()
  //     .subscribe((response: any) => {
  //       this.appcomponentservice.setIsModelOpen(false);
  //       if (response.result == "Failure") {
  //           this.verifyEFSvalue= true;
  //         // if (this.appcomponentservice.isModelOpen){
  //         //   this.modalService.close();
  //         //   this.appcomponentservice.setIsModelOpen(false);
  //         // }
  //         this.appcomponentservice.setIsModelOpen(false);
  //         // this.pinSidebar();

  //           // this.modalService.open(PreviewPopupComponent).onClosed().subscribe(res => {
  //           //   this.appcomponentservice.setIsModelOpen(false);
  //           //    });
  //       }
  //       else {
  //         this.verifyEFSvalue = false;
  //         this.appcomponentservice.setIsModelOpen(false);

  //       }
  //     }),
  //     (error: any) => {
  //       console.log("error", error);
  //     }
  // }

  openUserCommunityLink(): void {
    window.open(this.teamsChannelUrl, '_blank');
  }
  ensurePreTagHeaders(): void {
    if (this.autoScrollEnabled) {
      this.scrollToBottom();
    }
    console.log("ensurePreTagHeaders called");

    const preTags = this.el.nativeElement.querySelectorAll('pre');

    preTags.forEach((preTag: HTMLElement) => {
      // Only add the header if it doesn't already exist
      const existingHeader = preTag.querySelector('.preTagHeader');
      if (!existingHeader) {
        const codeTag = preTag.querySelector('code');
        if (codeTag?.className) {
          const classSplit = codeTag.className.split('-');
          const langName =
            classSplit.length > 1 &&
              classSplit[1] &&
              classSplit[1] !== 'none' &&
              classSplit[1] !== 'undefined'
              ? classSplit[1]
              : 'Plain Text';

          const headerDiv = this.render.createElement('div');
          this.render.addClass(headerDiv, 'preTagHeader');

          const leftSpan = this.render.createElement('span');
          this.render.addClass(leftSpan, 'clsLanguage');
          this.render.addClass(leftSpan, 'text-left');
          const leftText = this.render.createText(langName);
          this.render.appendChild(leftSpan, leftText);

          const rightSpan = this.render.createElement('a');
          this.render.addClass(rightSpan, 'clsCopy');
          this.render.addClass(rightSpan, 'text-right');
          const icon = this.render.createElement('i');
          this.render.addClass(icon, 'fa');
          this.render.addClass(icon, 'fa-clone');
          this.render.appendChild(rightSpan, icon);

          this.render.appendChild(headerDiv, leftSpan);
          this.render.appendChild(headerDiv, rightSpan);

          this.render.insertBefore(preTag, headerDiv, codeTag);

          this.render.listen(rightSpan, 'click', () => this.copyCode(preTag));
        }
      }
    });
  }

  copyCode(preTag: HTMLElement) {
    // this.isCopy = true;
    // var codeTag = preTag.querySelector('code');
    // if (codeTag != null) {
    //   const codeText = codeTag.innerText; // Extract the code text
    //   navigator.clipboard.writeText(codeText).then(() => {
    //     console.log('Message copied to clipboard');
    //   }).catch(err => {
    //     console.error('Could not copy text: ', err);
    //   });
    //   setTimeout(() => {
    //     this.isCopy = false;
    //   }, 3000);
    // }
  }


  // copyToClipboard(text: any, index : any) {
  //   this.isCopy = true;
  //   this.copyIndex = index;
  //   const innerText = text.replace(/\*\*/g, '');
  //   navigator.clipboard.writeText(innerText).then(() => {
  //     console.log('Message copied to clipboard');
  //   }).catch(err => {
  //     console.error('Could not copy text: ', err);
  //   });
  //   setTimeout(() => {
  //     this.isCopy = false;
  //   }, 3000);
  // }
  copyToClipboard(text: string, index: number) {
    this.isCopy = true;
    this.copyIndex = index;

    // Remove all occurrences of "**"
    //  let innerText = text.replace(/\*\*/g, '');
    // let innerText = text.replace(/(\*\*|`)/g, '');
    let innerText = text.replace(/(\\|[\*\*`])/g, '');



    // Find the position of the word "References"
    const referencesIndex = innerText.indexOf('References');
    if (referencesIndex !== -1) {
      // Split the text into two parts: before and after "References"
      const beforeReferences = innerText.substring(0, referencesIndex + 'References'.length);
      const afterReferences = innerText.substring(referencesIndex + 'References'.length);

      // Remove all <br> tags from the part after "References"
      const cleanedAfterReferences = afterReferences.replace(/<br>/g, '\n');

      // Combine the two parts back together
      innerText = beforeReferences + cleanedAfterReferences;
    }

    // Copy to clipboard
    navigator.clipboard.writeText(innerText).then(() => {
      console.log('Text copied to clipboard with formatting');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });

    setTimeout(() => {
      this.isCopy = false;
    }, 3000);
  }




  isAtBottom(container: HTMLElement): boolean {
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const currentScrollTop = container.scrollTop;

    // Check if the user is at the bottom, allowing for a small threshold (e.g., 15px)
    return scrollHeight - clientHeight <= currentScrollTop + 15;
  }

  adjustTextareaHeight(textarea: HTMLTextAreaElement): void {
    if (!textarea) return;

    const atBottom = this.isAtBottom(this.chatContainer.nativeElement);

    // Reset height to 'auto' so that the textarea can shrink
    textarea.style.height = 'auto';

    // Calculate the new height based on the content's scrollHeight
    const maxHeight = 100; // Max height for the textarea
    const newHeight = Math.min(textarea.scrollHeight + 1.5, maxHeight); // Ensure we do not exceed the max height

    // Set the height to the new height
    textarea.style.height = `${newHeight}px`;

    if (atBottom) {
      this.scrollToBottom();
    }
  }
  updateChatContainerHeight(messageBoxHeight: number): void {
    const wrapperHeight = window.innerHeight;
    const chatContainerHeight = wrapperHeight - messageBoxHeight - 50; // Adjusting for any extra space like margins or padding
    this.render.setStyle(this.chatContainer.nativeElement, 'height', `${chatContainerHeight}px`);
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        const chatContainer = this.chatContainer.nativeElement as HTMLElement;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }, 0);
    } catch (err) {
      console.error('Scroll to bottom failed:', err);
    }

  }
  getProfile() {
    this.https.get(GRAPH_ENDPOINT)
      .subscribe((profile: any) => {
        this.userName = profile.displayName;
        this.userId = profile.mail;
        this.displayName = profile.givenName;
      })
  }

  // Handle Enter and Shift + Enter key events
  // handleKeydown(event: KeyboardEvent, textarea: HTMLTextAreaElement): void {
  //   if (event.key === 'Enter' && !event.shiftKey) {
  //     // If Enter is pressed without Shift, call sendMessage function
  //     if (this.disableSendButton)
  //       return;
  //     this.sendMessage(false, "");
  //     event.preventDefault(); // Prevent the default action of adding a newline
  //   }
  //   // If Shift + Enter is pressed, allow the textarea to insert a new line
  // }

  handleKeydown(event: KeyboardEvent, textarea: HTMLTextAreaElement): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      // If Enter is pressed without Shift, call sendMessage function
      if (this.userPrompt.trim() === "" || this.disableSendButton) {
        event.preventDefault(); // Prevent the default action of adding a newline
        return;
      }
      this.sendMessage(false, "");
      event.preventDefault(); // Prevent the default action of adding a newline
    }

  }

  newChat() {
    //   if(this.appcomponentservice.isSendButtonDisabled || this.loadingService.isLoading){
    //    return;
    //   }
    //   this.resetDocuments();
    // }
    if (this.disableSendButton || this.loadingService.isLoading) {
      return;
    }
    this.resetVariables();
    this.errorMesg = false;
    this.isDefaultText = true;
    this.autoScrollEnabled = true;
    this.messages.next([]);
    this.guid = uuidv4();
    this.userPrompt = "";
    this.resetTextareaHeight(); // Reset height after message send


  }

  private isValidFile(file: File): boolean {
    return (file.type === "") ? file.name.includes('.vtt') : this.allowedTypes.includes(file.type);
  }

  // Method to get valid files from a FileList
  getValidFiles(fileList: FileList): File[] {
    const filesArray = Array.from(fileList); // Convert FileList to an array
    return filesArray.filter(file => this.isValidFile(file));
  }

  // Method to append valid files from one array to another
  appendValidFiles(toArray: File[], fromArray: File[]) {
    const validFiles = fromArray.filter(file => this.isValidFile(file));

    for (let i = 0; i < validFiles.length; i++) {
      toArray.push(validFiles[i]);
    }
  }

  private updateUploadLimitFlag(): void {
    const totalSize = this.getTotalSelectedFilesSize();
    // Check if file count exceeds the maximum limit
    if (this.selectedFiles.length > this.MAX_FILE_COUNT || totalSize > this.MAX_FILE_SIZE) {
      this.isUploadLimitExceeded = true; // Set the flag if exceeded
    } else {
      this.isUploadLimitExceeded = false; // Reset the flag if within limits
    }
  }

  // Helper method to get the total size of already selected files
  getTotalSelectedFilesSize(): number {
    return this.selectedFiles.reduce((sum, file) => sum + file.size, 0);
  }

  ngAfterViewInit() {
    const textarea = this.dynamicTextarea.nativeElement;
    // Ensure it starts with the correct height
    textarea.style.height = `${this.defaultHeight}px`;
  }

  resetTextareaHeight(): void {
    const textarea = this.dynamicTextarea.nativeElement;
    if (!textarea) return;

    // Reset height to the default height
    textarea.style.height = `${this.defaultHeight}px`;
  }

  getKBChecks() {
    this.chatService.getKBChecks().subscribe({
      next: res => {
        if (res.result == null) {
          return;
        }
        //var tempDate = moment.utc(res.last_updated, "DD-MMM-YYYY HH:mm").local().format("DD-MMM-YYYY HH:mm z");
        var tempDate = moment.utc(res.result.last_updated, "DD-MMM-YYYY HH:mm")
          .local()
          .format("MMM D, YYYY"); // Format with month abbreviation, day, year, time, and time zone

        this.lblIndexLastRefreshDate = tempDate;
      },
      error: (error: any) => {
        console.error('Error fetching blobs:', error);
      },
      complete: () => {
        console.log('Completed fetching blobs');
      }
    });
  }

}
