<style>

</style>

<div class="systemsettings system-settings-modal" [ngClass]="{'middleModelPopup': appcomponentservice.isPinChecked}">
  <div class="dds-modal-overlay" (click) = "close()"></div>

  <div ddsResize [ngClass]="{'dds-modal_lg': size === 'lg'}" class="dds-modal">
    <div class="dds-modal__header">
      <span class="dds-modal__title chat-settings">{{lblChatSettings}}</span>
      <dds-button size="md"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close system-settings-close"
      (clicked)="close()"></dds-button>
    </div>
    <!-- <hr class="dividertop line1"> -->

    <div class="dds-modal__body modal-body">

      <dds-textarea [(ngModel)]="systemSettingsData.systemprompt" [label]="textareaOptions.label"
        [minHeight]="textareaOptions.minHeight"
        [disabled]="textareaOptions.disabled" [isRequired]="textareaOptions.isRequired"
        [customClass]="textareaOptions.customClass" [isError]="textareaOptions.isError"
        [errorMessage]="textareaOptions.errorMessage" [errorState]="textareaOptions.errorState"
        [maxLength]="textareaOptions.maxLength" [maxLengthPosition]="textareaOptions.maxLengthPosition"
        [size]="textareaOptions.size" class="system-prompt">
      </dds-textarea>

      <dds-select [description]="personalitySelectOptions.description"
        [errorMessage]="personalitySelectOptions.errorMessage" [disabled]="personalitySelectOptions.disabled"
        [isError]="personalitySelectOptions.isError" [errorState]="personalitySelectOptions.errorState"
        [isResponsive]="personalitySelectOptions.isResponsive" [isRequired]="personalitySelectOptions.isRequired"
        [label]="personalitySelectOptions.label" [labelPosition]="personalitySelectOptions.labelPosition"
        [customClass]="personalitySelectOptions.customClass" [placeholder]="personalitySelectOptions.placeholder"
        [size]="personalitySelectOptions.size" [stickerWidth]="personalitySelectOptions.stickerWidth"
        [stickerShift]="personalitySelectOptions.stickerShift"
        [stickerMaxHeight]="personalitySelectOptions.stickerMaxHeight"
        [stickerIsDisabled]="personalitySelectOptions.stickerIsDisabled"
        [stickerPosition]="personalitySelectOptions.stickerPosition"
        [stickerIndent]="personalitySelectOptions.stickerIndent"
        [stickerCustomClass]="personalitySelectOptions.stickerCustomClass" [value]="systemSettingsData.personality"
        [list]="personalitySelectItems"
        class="personality-select" (valueChanged)="setPersionality($event)">
        <dds-select-item *ngFor="let item of personalitySelectItems" [disabled]="item.disabled"
          [counter]="item.counter" [heading]="item.heading" [customClass]="item.customClass"
          [value]="item.value"></dds-select-item>

      </dds-select>
      <br>
      <dds-select [description]="responseLengthSelectOptions.description"
        [errorMessage]="responseLengthSelectOptions.errorMessage" [disabled]="responseLengthSelectOptions.disabled"
        [isError]="responseLengthSelectOptions.isError" [errorState]="responseLengthSelectOptions.errorState"
        [isResponsive]="responseLengthSelectOptions.isResponsive" [isRequired]="responseLengthSelectOptions.isRequired"
        [label]="responseLengthSelectOptions.label" [labelPosition]="responseLengthSelectOptions.labelPosition"
        [customClass]="responseLengthSelectOptions.customClass" [placeholder]="responseLengthSelectOptions.placeholder"
        [size]="responseLengthSelectOptions.size" [stickerWidth]="responseLengthSelectOptions.stickerWidth"
        [stickerShift]="responseLengthSelectOptions.stickerShift"
        [stickerMaxHeight]="responseLengthSelectOptions.stickerMaxHeight"
        [stickerIsDisabled]="responseLengthSelectOptions.stickerIsDisabled"
        [stickerPosition]="responseLengthSelectOptions.stickerPosition"
        [stickerIndent]="responseLengthSelectOptions.stickerIndent"
        [stickerCustomClass]="responseLengthSelectOptions.stickerCustomClass"
        [list]="responseLengthSelectItems"
        [value]="systemSettingsData.responselength" (valueChanged)="setResponseLength($event)">

        <dds-select-item *ngFor="let item of responseLengthSelectItems" [disabled]="item.disabled"
          [counter]="item.counter" [heading]="item.heading" [customClass]="item.customClass"
          [value]="item.value"></dds-select-item>

      </dds-select>

    </div>
    <!-- <hr class="dividerbottom line2"> -->
    <div class="dds-modal__footer" *ngIf="isFooter">
      <div class="dds-modal__footer-content dds-modal__footer-content_left">
      </div>
      <div class="dds-modal__footer-content dds-modal__footer-content_right buttons-footer">
        <dds-button class="dds-modal__footer-item btn-reset" kind="silent" theme="dark" width="full"
          (clicked)="reset()">{{btnClose}}</dds-button>
        <div class="applybutton">

          <dds-button class="dds-modal__footer-item btn-Apply" width="full"
            (clicked)="onApply()">{{btnApply}}</dds-button>

        </div>
      </div>
    </div>
  </div>
</div>