import { Component, DebugElement } from '@angular/core';
import { I18nService, EN, ITranslation } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { AppComponentService } from '../Services/appcomponentservice';

@Component({
  selector: 'app-whatsnew',
  templateUrl: './whatsnew.component.html',
  styleUrls: ['./whatsnew.component.css']
})
export class WhatsnewComponent {
  lang1: string;
  private destroy = new Subject<void>();
  // whatsnew1: string;
  // whatsnew2: string;
  // whatsnew3: string;
  // whatsnew4: string;
  // whatsnew5: string;
  // userCommunityUrl: string = 'https://teams.microsoft.com/l/team/19%3AFDYRcbgfOx7MpQhKkMEcwGIA0oJS7DainlCatWTAUjo1%40thread.tacv2/conversations?groupId=a9375551-e68a-4e03-a57b-54f04124fda7&tenantId=36da45f1-dd2c-4d1f-af13-5abe46b99921';
  // userCommunityText: string;
  // whatnew12: string;

  releaseNote: string;
  whatsNew: string;
  lblAgentCentral: string;
  descAgentCentral: string;
  lblEFSChat: string;
  descEFSChat: string;
  lblDeloitteVoice: string;
  descDeloitteVoice: string;
  lblGenDUpdates: string;
  descGenDUpdates1: string;
  descGenDUpdates2: string;
  descGenDUpdates3: string;
  lblGeneralImprovements: string;
  descGeneralImprovements: string;
  constructor( private modalService: ModalService, private i18n: I18nService,public appcomponentservice: AppComponentService
  ){}

  // @Input() isInverse: boolean;
  manageLocale(){
    this.i18n
    .getLocaleObs()
    .pipe(takeUntil(this.destroy))
    .subscribe((locale) => {
      switch (locale) {
        case "FR":
        this.lang1 = 'fr';
        this.whatsNew = "Quoi de neuf ?";
        this.releaseNote="Note de la version 2025-31-01:";
        // this.whatsnew1 = 'Présentation de la';
        // this.userCommunityText = "Communauté des Utilisateurs de Gen-D";
        // this.whatnew12 = "une communauté collaborative pour faire entendre votre voix";
        // this.whatsnew2 = "Ajout des paramètres de discussion pour un contrôle utilisateur granulaire";
        // this.whatsnew3 = 'Nouvelle interface utilisateur et éléments visuels';
        // this.whatsnew4 = "Amélioration de l'expérience utilisateur et meilleure stabilité";
        // this.whatsnew5 = 'Optimisation des performances avec des interactions plus intuitives';
        this.lblAgentCentral ="Agent Central";
        this.descAgentCentral = "Outils et ressources centralisés pour une productivité accrue.";
        this.lblEFSChat ="EFS Chat (Bêta)";
        this.descEFSChat = "Discussion avec une base de connaissances sélectionnée des Services Financiers d'Engagement.";
        this.lblDeloitteVoice ="Deloitte Voice - Révision (Bêta)";
        this.descDeloitteVoice = "Réviseur de conformité de marque à la demande.";
        this.lblGenDUpdates = "Mises à Jour de Gen-D";
        this.descGenDUpdates1 = "Historique de chat Gen-D : Accédez et reprenez les sessions de chat précédentes.";
        this.descGenDUpdates2 = "Paramètres de chat Gen-D mis à jour pour une meilleure convivialité.";
        this.descGenDUpdates3 = "Vidéo d'introduction à l'intégration de Gen-D.";
        this.lblGeneralImprovements= "Améliorations Générales";
        this.descGeneralImprovements = "Améliorations générales de l'interface utilisateur pour une meilleure convivialité.";
        break;
      default:
        this.lang1 = 'en';
        this.whatsNew = "What's New";
        this.releaseNote="Release Note 2025-01-31:";
        // this.whatsnew1 = 'Introducing';
        // this.userCommunityText = "Gen-D User Community";
        // this.whatnew12 = ": a collaborative community to get your voice heard";
        // this.whatsnew2 = 'Added Chat Settings for granular user control';
        // this.whatsnew3 = 'Brand new UI and visual elements';
        // this.whatsnew4 = 'Improved UX and product behavior ';
        // this.whatsnew5 = 'Performance optimization along with more intuitive interaction';
        this.lblAgentCentral ="Agent Central";
        this.descAgentCentral = "Centralized tools and resources for enhanced productivity.";
        this.lblEFSChat ="EFS Chat (Beta)";
        this.descEFSChat = "Chat with curated Engagement Financial Services knowledgebase.";
        this.lblDeloitteVoice = "Deloitte Voice - Revision (Beta)";
        this.descDeloitteVoice = "On-demand branding compliance revisor.";
        this.lblGenDUpdates= "Gen-D Updates";
        this.descGenDUpdates1 = "Gen-D chat history: Access and resume previous chat sessions.";
        this.descGenDUpdates2 = "Updated Gen-D chat settings for improved usability.";
        this.descGenDUpdates3 = "Gen-D onboarding intro video.";
        this.lblGeneralImprovements="General Improvements";
        this.descGeneralImprovements = "General UI improvements for enhanced usability.";
        break;
    }
  });
}
  close() {
    this.modalService.close();
  }

  ngOnInit() {
  this.manageLocale();

    //centering chat settings modal
    const sidebar = document.getElementById("sidebar")
    if (!sidebar?.classList.contains('show') && !sidebar?.classList.contains('hidden')) { //if (!sidebar?.classList.contains('show') || !sidebar?.classList.contains('hidden')) {
      this.appcomponentservice.setIsPinChecked(true);
    }
    else {
      if (sidebar?.classList.contains('show')) {
        this.appcomponentservice.setIsPinChecked(true);
      }
      else {
        this.appcomponentservice.setIsPinChecked(false);
      }
    }
  }
}
