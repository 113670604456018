import { Component, OnInit } from '@angular/core';
import { AppComponentService } from '../Services/appcomponentservice';
import { RecentAgentsService } from '../Services/recent-agent.service';

@Component({
  selector: 'app-deloitte-voice',
  templateUrl: './deloitte-voice.component.html',
  styleUrl: './deloitte-voice.component.css'
})
export class DeloitteVoiceComponent implements OnInit {
  agentPath = "/deloitte-voice";
  constructor(
    private recentAgentsService: RecentAgentsService,
    public appComponentService: AppComponentService, 

  ) {
  }
  
  ngOnInit(): void {
    this.appComponentService.enableNewTaskButton();
    this.appComponentService.setIsNewChatVisible(false); 
    this.recentAgentsService.addRecentPath(this.agentPath);  
  }
}
