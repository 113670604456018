import { Component, EventEmitter, Input, OnInit, forwardRef, HostListener, Output, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizedText } from 'src/app/model/agent.model';
import { I18nService } from '@usitsdasdesign/dds-ng/shared/i18n';
import { Subject, takeUntil } from 'rxjs';


 
export interface DropdownOption {
  label: LocalizedText;
  value: any;
  disabled?: boolean;
}
 
@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true
    }
  ]
})
export class SelectDropdownComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder: string = 'Select an option';
  @Input() disabled: boolean = false;
  @Input() options: DropdownOption[] = [];
  language: string = 'en';
  @Output() valueChange: EventEmitter<any> = new EventEmitter(); 
  private destroy = new Subject<void>(); 
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;

 
  private _value: any = null;
  isOpen: boolean = false;
  selectedOption: DropdownOption | null = null;
 
  // Callbacks for the ControlValueAccessor
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};
 
  ngOnInit() {
    // Initialize selectedOption based on the existing value
    this.selectedOption = this.options.find(opt => opt.value === this._value) || null;
    this.manageLocale();
  }

  constructor(private i18n: I18nService){}

  manageLocale(): void {
    this.i18n
      .getLocaleObs()
      .pipe(takeUntil(this.destroy))
      .subscribe((locale) => {
        switch (locale) {
          case "FR":
            this.language = 'fr'
            break;
          default:
            this.language = 'en'
            break;
        }
      });
  }   

  writeValue(value: any): void {
    this._value = value;
    this.selectedOption = this.options.find(opt => opt.value === this._value) || null;
  }
 
  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }
 
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
 
  toggleDropdown(): void {
    if (!this.disabled) {
      this.isOpen = !this.isOpen;
    }
  }
 
  selectOption(option: DropdownOption): void {
    if (option.disabled) {
      return;
    }
 
    this.selectedOption = option;
    this._value = option.value;
    this.onChange(this._value);  // Notify Angular of the change
    this.valueChange.emit(this._value); // Emit the new value to the parent
    this.onTouched(); // Mark as touched
    this.isOpen = false; // Close the dropdown when an option is selected
  }
 
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.dropdownContainer.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
 
}