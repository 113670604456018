import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-file-display',
  templateUrl: './file-display.component.html',
  styleUrls: ['./file-display.component.css']
})
export class FileDisplayComponent {
  @Input() files: File[] = [];
  @Output() fileRemoved = new EventEmitter<File>();

  removeFile(file: File): void {
    this.fileRemoved.emit(file);
  }
}
