import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-deloitte-voice-error',
  templateUrl: './deloitte-voice-error.component.html',
  styleUrls: ['./deloitte-voice-error.component.css'],
  standalone: false
})
export class DeloitteVoiceErrorComponent {
  @Input() lblErrorHeader!: string;
  @Input() lblErrorDetailsPart1!: string;
  @Input() lblErorrDetailsTeamsChannel!: string;
  @Input() lblErrorDetailsPart2!: string;
  @Input() lblErrorDetailsHere!: string;
  @Input() lblErrorDetailsPart3!: string;
  @Input() teamsChannelUrl!: string;
  @Input() serviceNowLink!: string;
  @Input() lblGoBack!: string;

  @Output() goBackEvent = new EventEmitter<void>();

  goBack(): void {
    this.goBackEvent.emit(); // Emit the event
  }
}
