<div class="scroll-container">
   <div class="agent-central-container">
      <div class="header">
         <h1>{{lblPageTitle}}</h1>
         <p class="description">
            {{lblPageDescriptionPart1}}
            <!-- <a [href]="tutorialLink" target="_blank">{{tutorialLinkDescription}}</a>{{lblPageDescriptionPart2}} -->
         </p>
      </div>
      <div class="all-agents">
         <div class="all-agents-header">
            <h2>{{lblAllAgents}}</h2>
         </div>
         <div class="card-container">
            <div class="agent-card" 
            *ngFor="let agent of allAgents" 
            [ddsTooltip]="ComingSoontext"
            [tooltipInvokeType]="'hover'"
            [tooltipSize]="'lg'"
            [tooltipType]="'regular'"
            [tooltipIsDisabled]="agent.isTooltipDisabled"
            [ngClass]="{'disabled':agent.disabled}"
            (click)="navigateTo(agent)"
            >
            <div class="card-left">
               <img class="agent-icon" src="../../assets/images/sparkles.svg">
            </div>
            <div class="card-right">
               <h3>{{ agent.name[this.language] }} <span style="color: #DA291C;">{{agent.version}}</span></h3>
               <p>{{ agent.description[this.language] }}</p>
               <p class="warningText">{{agent.warning[this.language]}}</p>
            </div>
         </div>
      </div>
      <!-- <div class="arrow-navigation">
         <button 
         class="arrow" 
         (click)="prevAgents()" 
         [disabled]="currentIndex === 0" 
         aria-label="Previous"
         >
         <img src="../../assets/images/double_arrow_left.svg" alt="Left Arrow">
         </button>
         <button 
         class="arrow" 
         (click)="nextAgents()" 
         [disabled]="currentIndex + 6 >= filteredAgents.length" 
         aria-label="Next"
         >
         <img src="../../assets/images/double_arrow_right.svg" alt="Right Arrow">
         </button>
      </div> -->
   </div>
</div>
</div>