<div id="homeContent" *ngIf="verifyEFSvalue">
   <div class="content-container" #chatContainer (scroll)="onScroll($event)">
      <div class="content-wrapper centered-text" [hidden]="!isDefaultText">
         <div class="txtHiNameStyle">
            <img src="../assets/images/rocket.png" />
            <span class="txtHiNameStyle2"> {{lblNameGreeting}}, {{displayName}}!</span>
         </div>
         <div class="txtPart2Style">
            {{lblHelpGreeting}}
         </div>
         <div class="txtPart345">
            <span class="txtPart4Style">
            {{lblGenDIntro2}}
            </span>
            <a class="txtPart5Style" href="{{KB_URL}}" target="_blank">
            <!-- {{lblEFS_01}} -->
             {{lblIndexLastRefreshDate}}
            </a>
         </div>
         <br/>
         
         
       
      </div>
      <div class="dynamic-content" id="dynamicContent" [hidden]="isDefaultText" >
         <ng-container *ngFor="let message of messages$ | async let i = index" >
            <div class="content-with-icon userPrompt" *ngIf="message.user === 'user'">
               <i class="fa fa-user-circle imgDynamicContent1" aria-hidden="true"></i>
               <div class="message">
                  {{ message.text }}
               </div>
               <ng-container *ngIf="setUserMessage(message.text)" ></ng-container>
            </div>
            <div class="bot-content-with-icon botResponse" *ngIf="message.user === 'bot'">
               <i class="imgDynamicContent2Background"><img class="imgDynamicContent2 " src="../assets/images/sparkles.png" /></i>
               <div class="botmessage">
                  <markdown>{{ message.text }}</markdown>
               </div>
               <div class="thumbs-icons" *ngIf="i != messages.value.length -1|| !isStreaming">
                  <img *ngIf="!this.dislikedIndices.has(i) && !likedIndices.has(i)" class="imgActionsOnDynamicContent likeButton" src="../assets/images/likeFinal.png" (click)="openFeedbackLikeModel(i, message.text)"> 
                  <img *ngIf="!this.likedIndices.has(i) && !dislikedIndices.has(i)" class="imgActionsOnDynamicContent dislikeButton" src="../assets/images/dislikeFinal.png" (click)="openFeedbackDislikeModel(i, message.text)">                      
                  <img *ngIf="dislikedIndices.has(i)" class="imgActionsOnDynamicContent dislikeButton" src="../assets/images/dislikeIconGreen.png">
                  <img *ngIf="likedIndices.has(i)" class="imgActionsOnDynamicContent likeButton" src="../assets/images/likeIconGreen.png">
                  <img *ngIf="!isCopy || i != copyIndex" class="imgActionsOnDynamicContent" src="../assets/images/clipboard.png" (click)="copyToClipboard(message.text, i)" />
                  <img *ngIf="isCopy && i === copyIndex" class="imgActionsOnDynamicContent" src="../assets/images/check-circle.png" />
               </div>
            </div>
         </ng-container>
         <!-- using below markdown for copy clipboard functionality -->
         <!-- <markdown style="display: none;" id="tempMarkDown" [data]="tempCopiedText"></markdown> -->
      </div>
   </div>
   <div class="chat-main-container" id="chatMainContainer">
      <!-- <div class="scroll-bottom-container" *ngIf="showScrollToBottom" (click)="OnScrollToBottom()">
         <i class="fa fa-arrow-down" aria-hidden="true"></i>
         </div>  -->
      <div *ngIf="errorMesg" class="error-message">
         {{lblSorrySomething1}} <span class="userCommunity" (click)="openUserCommunityLink()">
         {{lblUserCommunity}}</span>{{lblSorrySomething2}}
      </div>
      <div class="chat-input" id="chatinput">
         <!-- <input type="text" placeholder="Ask me a question..." [(ngModel)]='userPrompt'> -->
         <textarea rows="1" placeholder={{lblAskQuestion}} [(ngModel)]='userPrompt'
         (input)="adjustTextareaHeight(dynamicTextarea)"   #dynamicTextarea
         (keydown)="handleKeydown($event, dynamicTextarea)" class="userinput"></textarea>
         <!-- <input type="text" placeholder="Ask me a question..." [(ngModel)]='userPrompt'> -->
         <button type="submit" [ngClass]="{'disableSednButton':disableSendButton}" class="send-button" (click)="sendMessage(false, userPrompt)" (keyup.enter)="sendMessage(false, userPrompt)" [disabled]="disableSendButton">
         <img   [ngClass]="{'send-button-disable':  disableSendButton}"  id="imgSendStyle" src="../assets/images/send_new.png" />
         </button>
      </div>
   </div>
   </div>
   <div  *ngIf="!verifyEFSvalue" >
      <app-error></app-error>
   </div>
   <!-- test -->
