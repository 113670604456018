import { Component } from '@angular/core';

@Component({
  selector: 'app-deloitte-voice-create',
  templateUrl: './deloitte-voice-create.component.html',
  styleUrl: './deloitte-voice-create.component.css'
})
export class DeloitteVoiceCreateComponent {

}
