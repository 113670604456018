import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading: boolean = false;
  usermessageService: any;
  deletechat: boolean =false;
  stopstreaming: boolean;
  setLoadingState(state: boolean) {
    this.isLoading = state;
  }
  setUserMessage(state: any) {
    this.usermessageService = state;
  }
  setdeleteConv(state: boolean) {
    this.deletechat = state;
  }
  setstopstreaming(state:boolean) {
    this.stopstreaming = state;
  }

}
