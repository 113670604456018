<div
  class="upload-container"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave()"
  (drop)="onDrop($event)"
  (click)="fileInput.click()"
  [class.dragover]="dragOver"
>
<input
  #fileInput
  type="file"
  (change)="onFilesAdded($event)"
  multiple
  [accept]="acceptedFileTypesString"
  style="display: none;"
/>
<div class="upload-content">
<img src="../../../assets/images/file-upload-icon.svg" alt="Upload Icon" class="upload-icon" />
<span class="common-text">{{lblDragHere}}</span>
<p *ngIf="fileLimit" class="common-text bold-text">
      {{ fileLimit === 1 ? lblUploadDocOne : lblUploadDocMultiple }}
      <!-- You can upload {{ fileLimit === 1 ? 'a document' : 'up to ' + fileLimit + ' documents' }}
      up to {{ maxFileSize }}MB in size. -->
</p>
<p class="common-text">{{lblSupportedFiles}}</p>
<p *ngIf="errorMessage" class="error-message common-text">{{ errorMessage }}</p>
</div>
</div>